import React from 'react';
import { keyframes } from '@emotion/react';
import { styled } from '@mui/system';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { colors } from '../../../themes/theme';

const Container = styled('div')({
  maxWidth: '100%',
  width: '100%',
  margin: '0px auto',
});

const SearchContainer = styled('div')({
  position: 'relative',
  width: '100%',
  height: '56px',
  marginBottom: {
    xs: '0px',
    sm: '20px',
    md: '20px',
    lg: '20px',
  },
  '@media (max-width:600px)': {
    height: '48px',
  },
  '@media (min-width:600px) and (max-width:960px)': {
    height: '52px',
  },
  '@media (min-width:960px)': {
    height: '56px',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    inset: '-2px',
    background: colors.primaryGradient,
    borderRadius: '9999px',
    zIndex: 0,
    animation: 'backgroundAnimation 3s linear infinite',
  },
  '&:focus-within::before': {
    boxShadow: `0 0 0 0 ${colors.boxShadowFocus}`,
  },
  '@keyframes backgroundAnimation': {
    '0%': {
      backgroundPosition: '0% 50%',
    },
    '100%': {
      backgroundPosition: '100% 50%',
    },
  },
});

const InputWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'white',
  borderRadius: '9999px',
  height: '100%',
  padding: '0 16px',
  zIndex: 1,
  border: 'none',
});

const Input = styled('input')({
  flex: 1,
  border: 'none',
  outline: 'none',
  fontSize: '16px',
  marginLeft: '8px',
  '&::placeholder': {
    color: colors.placeholder,
  },
});

const GoButton = styled('button')({
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  fontSize: '16px',
  color: colors.placeholder,
  '&:hover': {
    color: colors.textHover,
    scale: '1.1',
  },
});

const ClearButton = styled('button')({
  background: 'none',
  cursor: 'pointer',
  marginRight: '10px',
  color: colors.placeholder,
  '&:hover': {
    color: colors.textHover,
    scale: '1.05',
    border: 'none',
  },
  width: '22px',
  height: '22px',
  fontWeight: 'bold',
  border: 'none',
});

const FilterChip = styled('div')(({ theme }) => ({
  display: 'inline-block',
  padding: '6px 12px',
  margin: '0 8px 8px 0',
  borderRadius: '20px',
  fontSize: '12px',
  fontWeight: 'bold',
  color: colors.white,
  backgroundColor: colors.filterChipBackground,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
}));

const pulseAnimation = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 ${colors.pulseShadow};
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(0, 123, 255, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
  }
`;

const LoadingSpinner = styled('div')({
  background: colors.loadingGradient,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  animation: `${pulseAnimation} 2.5s ease-in-out infinite`,
  padding: '2px',
  borderRadius: '100px',
  width: '26px',
  height: '26px',
});

const StyledDataGridPro = styled(DataGridPro)(() => ({
  borderRadius: '20px',
  padding: '10px',
  '& .MuiDataGrid-columnHeaders': {
    fontSize: '1rem',
    fontWeight: '800',
  },
  '& .MuiDataGrid-cell': {
    fontSize: '0.875rem',
    fontWeight: 'semibold',
  },
  '& .MuiDataGrid-main > div:nth-child(3)': {
    display: 'none',
  },
  '& MuiDataGrid-virtualScroller ': {
    minHeight: '200px',
  },
}));

const ArrowIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 10C3 9.58579 3.33579 9.25 3.75 9.25H14.3879L10.2302 5.46967C9.93731 5.20644 9.91489 4.75921 10.1781 4.46636C10.4413 4.17351 10.8885 4.15109 11.1814 4.41432L16.6814 9.41432C16.8325 9.55147 16.9167 9.74981 16.9167 9.95833C16.9167 10.1669 16.8325 10.3652 16.6814 10.5023L11.1814 15.5023C10.8885 15.7656 10.4413 15.7432 10.1781 15.4503C9.91489 15.1575 9.93731 14.7102 10.2302 14.447L14.3879 10.75H3.75C3.33579 10.75 3 10.4142 3 10Z"
    />
  </svg>
);

export {
  Container,
  SearchContainer,
  InputWrapper,
  Input,
  GoButton,
  ClearButton,
  FilterChip,
  LoadingSpinner,
  StyledDataGridPro,
  ArrowIcon,
};
