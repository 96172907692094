import React from 'react';
import { Typography, Box } from '@mui/material';
import { FilterChip } from './styled.js';
import dayjs from 'dayjs';

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return dayjs(date).format('MMMM D, YYYY');
};

const FilterChips = ({ filters, sort, limit, offset }) => (
  <Box sx={{ mb: 2, textTransform: 'uppercase' }}>
    <Typography
      variant="body1"
      gutterBottom
      style={{ color: '#182848', textTransform: 'capitalize' }}
    >
      {filters?.length > 0 || limit || offset ? 'AI Filters:' : ''}
    </Typography>
    {!!filters &&
      Array.isArray(filters) &&
      filters.map((filter, index) => {
        const value =
          filter.name === 'authorized_date'
            ? formatDate(filter.value)
            : filter.value;
        return (
          <FilterChip key={index}>
            {`${filter.name} ${filter.operator === '@>' ? ':' : filter.operator} ${typeof value === 'string' ? value.replaceAll('_', ' ') : value}`}
          </FilterChip>
        );
      })}
    {!!sort &&
      Array.isArray(sort) &&
      sort.map((sortItem, index) => (
        <FilterChip key={`sort-${index}`}>
          {`Sort by ${sortItem.field} ${sortItem.value}`}
        </FilterChip>
      ))}
    {!!limit && <FilterChip>{`Limit: ${limit}`}</FilterChip>}
  </Box>
);

export default FilterChips;
