import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { SButton } from './style';
import firebaseAuth from '../../services/api/firebase-service';

const ResetPasswordRequest = ({ setErrorMessage }) => {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [emailId, setEmailId] = useState('');
  const [errors, setErrors] = useState({ email: '' });

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSignInClick = () => {
    window.location.pathname = '/';
  };

  const handleResetSubmit = async (event) => {
    event.preventDefault();
    let formErrors = {};

    if (!emailId) {
      formErrors.email = 'Email is required';
    } else if (!validateEmail(emailId)) {
      formErrors.email = 'Invalid email address';
    }

    if (Object.keys(formErrors).length === 0) {
      await firebaseAuth
        .firebaseSendPassResetEmail(emailId)
        .then(() => {
          setSubmitSuccess(true);
        })
        .catch(() => {
          setErrorMessage(
            'Unable to process request at this time. Please try again in a few minutes.'
          );
        });
    } else {
      setErrors(formErrors);
    }
  };

  const handleTryAgainClick = () => {
    setSubmitSuccess(false);
  };

  const handleError = (e) => {
    setErrors({ email: '' });
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            style={{ fontWeight: 'bold' }}
          >
            Reset Your Password
          </Typography>
          {submitSuccess ? (
            <>
              <Typography
                variant="body1"
                component="div"
                sx={{ mt: 3, whiteSpace: 'pre-wrap' }}
              >
                Thanks! If{' '}
                <Box fontWeight="fontWeightBold" display="inline">
                  {emailId}
                </Box>{' '}
                matches an email we have on file, you'll receive an email with
                further instructions on resetting your password.{' \n'}
                {' \n'}
                If you don't receive an email within the next few minutes,{' '}
                <Link href="#" component="span">
                  <Typography
                    component="span"
                    variant="body1"
                    style={{ fontWeight: 'bold', cursor: 'pointer' }}
                    onClick={handleTryAgainClick}
                  >
                    please try again.
                  </Typography>
                </Link>
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="body1" sx={{ mt: 3 }}>
                Enter the email address associated with your account and we'll
                send you a link to reset your password.
              </Typography>

              <Box noValidate sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography component="span" variant="h6">
                      Email
                    </Typography>
                    <TextField
                      sx={{ mt: 1.2 }}
                      required
                      fullWidth
                      id="email"
                      label="Enter your email"
                      name="email"
                      autoComplete="email"
                      error={Boolean(errors.email)}
                      helperText={errors.email}
                      onChange={(event) => {
                        setEmailId(event.target.value);
                        handleError();
                      }}
                    />
                  </Grid>
                </Grid>
                <SButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3 }}
                  onClick={handleResetSubmit}
                >
                  Continue
                </SButton>
              </Box>
            </>
          )}

          <Link href="#" component="span" display="grid" sx={{ mt: 3 }}>
            <Typography
              component="span"
              variant="body1"
              align="center"
              style={{ fontWeight: 'bold', cursor: 'pointer' }}
              onClick={handleSignInClick}
            >
              Return to sign-in
            </Typography>
          </Link>
        </Box>
      </Container>
    </>
  );
};
export default ResetPasswordRequest;
