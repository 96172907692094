import React from 'react';
import {
  SearchContainer,
  InputWrapper,
  Input,
  GoButton,
  ClearButton,
  LoadingSpinner,
} from './styled.js';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import EastIcon from '@mui/icons-material/East';
import { store, updateStore } from '../../../data/store.js';
import { useStore } from '@tanstack/react-store';
import { Box } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const AiSearchBar = ({ handleSearch, fetchTransactions }) => {
  const query = useStore(store, (state) => state.query);
  const isAiSearchLoading = useStore(store, (state) => state.isAiSearchLoading);
  const handleKeyPress = (event) => {
    if (query.length > 0) {
      if (event.key === 'Enter') {
        handleSearch(query);
      }
    }
  };

  const handleClear = () => {
    updateStore({
      query: '',
      aiInterpretation: null,
      hasCritiqueAnimated: false,
      critique: null,
      aiCritiqueError: '',
    });
    if (fetchTransactions) {
      fetchTransactions({ background: true });
    }
  };

  return (
    <SearchContainer>
      <InputWrapper>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient
              id="iconGradient"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="100%"
            >
              <stop offset="0%" stopColor="#5aa8ff" />
              <stop offset="100%" stopColor="#ff6482" />
            </linearGradient>
          </defs>
          <AutoAwesomeIcon style={{ fill: 'url(#iconGradient)' }} />
        </svg>
        <Input
          placeholder="Search with AI"
          value={query}
          onChange={(e) => updateStore({ query: e.target.value })}
          onKeyPress={handleKeyPress}
          sx={{
            fontSize: {
              xs: '14px',
              sm: '16px',
              md: '18px',
            },
          }}
        />
        {query.length > 0 && (
          <ClearButton onClick={handleClear}>
            <ClearIcon style={{ fill: 'url(#iconGradient)' }} />
          </ClearButton>
        )}
        {isAiSearchLoading ? (
          <LoadingSpinner>
            <Box
              sx={{
                width: '20px',
                height: '20px',
                backgroundColor: 'white',
                borderRadius: '100px',
              }}
            />
          </LoadingSpinner>
        ) : (
          <GoButton
            onClick={() => handleSearch(query)}
            disabled={query.length === 0}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <linearGradient
                  id="iconGradient"
                  x1="0%"
                  y1="0%"
                  x2="100%"
                  y2="100%"
                >
                  <stop offset="0%" stopColor="#5aa8ff" />
                  <stop offset="100%" stopColor="#ff6482" />
                </linearGradient>
              </defs>
              <EastIcon style={{ fill: 'url(#iconGradient)' }} />
            </svg>
          </GoButton>
        )}
      </InputWrapper>
    </SearchContainer>
  );
};

export default AiSearchBar;
