import React, { useState } from 'react';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { SButton } from './style';
import firebaseAuth from '../../services/api/firebase-service';
import SubmitPasswordForm from '../../common/SubmitPasswordForm';
import { useNavigate } from 'react-router-dom';

const ResetPasswordRedirect = ({ resetCode, setResetCode, emailId }) => {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [errors, setErrors] = useState({ password: '', confirmPassword: '' });
  const [newPassword, setNewPassword] = useState({
    password: '',
    confirmPassword: '',
  });
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    letter: false,
    number: false,
    specialChar: false,
    validChars: true,
  });
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const validatePassword = (password) => {
    const criteria = {
      length: password.length >= 8,
      letter: /[A-Za-z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[!@#$%^&*]/.test(password),
      validChars: /^[A-Za-z\d!@#$%^&*]*$/.test(password),
    };
    setPasswordCriteria(criteria);
    const strength =
      Object.values(criteria).reduce((acc, curr) => acc + (curr ? 1 : 0), 0) *
      20;
    setPasswordStrength(strength);
    return (
      criteria.length &&
      criteria.letter &&
      criteria.number &&
      criteria.specialChar &&
      criteria.validChars
    );
  };
  const handlePasswordResetSubmit = async (event) => {
    event.preventDefault();
    let formErrors = {};

    if (!newPassword.password) {
      formErrors.password = 'Password is required';
    } else if (!validatePassword(newPassword.password)) {
      formErrors.password = 'Password does not meet the required criteria.';
    }

    if (!newPassword.confirmPassword) {
      formErrors.confirmPassword = 'Please confirm your password';
    } else if (newPassword.password !== newPassword.confirmPassword) {
      formErrors.confirmPassword = 'Passwords do not match';
    }

    if (Object.keys(formErrors).length === 0) {
      await firebaseAuth
        .firebaseConfirmPasswordReset(resetCode, newPassword.password)
        .catch((error) => {
          console.error(error);
          setResetCode('INVALID'); //TODO: add more descriptive error-catching
        });
      setSubmitSuccess(true);
    } else {
      setErrors(formErrors);
    }
  };

  const handleReturnToSignIn = () => {
    navigate('/');
  };

  const handleTryAgainClick = () => {
    navigate('/login/reset');
  };

  const handleError = (e) => {
    if (errorMessage) setErrorMessage('');
    setErrors({ email: '', password: '' });
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            style={{ fontWeight: 'bold' }}
          >
            Reset Your Password
          </Typography>
          {submitSuccess ? (
            <>
              <Typography variant="body1" sx={{ mt: 3 }}>
                Your password has been reset. You can now{' '}
                <Link href="#" component="span">
                  <Typography
                    component="span"
                    variant="body1"
                    align="center"
                    style={{ fontWeight: 'bold', cursor: 'pointer' }}
                    onClick={handleReturnToSignIn}
                  >
                    sign in
                  </Typography>
                </Link>{' '}
                with your new credentials.
                {/* TODO: add redirect links */}
              </Typography>
            </>
          ) : resetCode !== 'INVALID' ? (
            <>
              <Typography
                variant="body1"
                component="div"
                sx={{ mt: 3, whiteSpace: 'pre-wrap' }}
              >
                Please enter a new password for the account associated with{' '}
                <Box fontWeight="fontWeightBold" display="inline">
                  {emailId}
                </Box>
                .{' \n'}
                {' \n'}
                If you didn't intend to reset the password for this account, you
                can{' '}
                <Link href="#" component="span">
                  <Typography
                    component="span"
                    variant="body1"
                    style={{ fontWeight: 'bold', cursor: 'pointer' }}
                    onClick={handleTryAgainClick}
                  >
                    send another password reset request
                  </Typography>
                </Link>{' '}
                or{' '}
                <Link href="#" component="span">
                  <Typography
                    component="span"
                    variant="body1"
                    style={{ fontWeight: 'bold', cursor: 'pointer' }}
                    onClick={handleReturnToSignIn}
                  >
                    return to sign-in
                  </Typography>
                </Link>
                .
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handlePasswordResetSubmit}
                sx={{ mt: 3 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <SubmitPasswordForm
                      handleError={handleError}
                      errors={errors}
                      registerUser={newPassword}
                      setRegisterUser={setNewPassword}
                      validatePassword={validatePassword}
                      passwordStrength={passwordStrength}
                      passwordCriteria={passwordCriteria}
                    />
                  </Grid>
                </Grid>
                <SButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3 }}
                >
                  Continue
                </SButton>
              </Box>
            </>
          ) : (
            <>
              <Typography variant="body1" sx={{ mt: 3 }}>
                This password reset link is no longer valid. Please{' '}
                <Link href="#" component="span">
                  <Typography
                    component="span"
                    variant="body1"
                    style={{ fontWeight: 'bold', cursor: 'pointer' }}
                    onClick={handleReturnToSignIn}
                  >
                    return to sign-in
                  </Typography>
                </Link>{' '}
                or{' '}
                <Link href="#" component="span">
                  <Typography
                    component="span"
                    variant="body1"
                    style={{ fontWeight: 'bold', cursor: 'pointer' }}
                    onClick={handleTryAgainClick}
                  >
                    try again
                  </Typography>
                </Link>
                .{/* TODO: add redirect links */}
              </Typography>
            </>
          )}
        </Box>
      </Container>
    </>
  );
};
export default ResetPasswordRedirect;
