// ReportFilter.js
import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import {
  Tooltip,
  useMediaQuery,
  useTheme,
  InputAdornment,
  Box,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import { colors } from '../../../themes/theme';
import { ResetButton } from './styled';
import { addCommasToNumber } from './addCommasToNumber';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const ReportFilter = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  income,
  costOfGoodsSold,
  operatingExpenses,
  netProfit,
  minDateRangeLocal,
  maxDateRangeLocal,
  handleReset,
  isResetDisabled,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleDateChange = (newDateRange) => {
    if (newDateRange[0] && newDateRange[1]) {
      setStartDate(newDateRange[0].toDate());
      setEndDate(newDateRange[1].toDate());
    }
  };

  const MobileView = () => (
    <Box>
      <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
        Date Range
      </Typography>
      <Box display="flex" flexDirection="column" gap={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateRangePicker
            value={[dayjs(startDate), dayjs(endDate)]}
            onChange={handleDateChange}
            minDate={dayjs(minDateRangeLocal)}
            maxDate={dayjs(maxDateRangeLocal)}
            slots={{ field: SingleInputDateRangeField }}
            slotProps={{
              textField: {
                size: 'small',
                fullWidth: true,
                sx: {
                  '& input': {
                    fontSize: '0.75rem',
                    textAlign: 'center',
                  },
                },
              },
              field: {
                format: 'MM/DD/YYYY',
              },
            }}
          />
        </LocalizationProvider>
        <Tooltip title="Reset Date Range" placement="right" arrow>
          <ResetButton
            disabled={isResetDisabled}
            variant="contained"
            color="primary"
            onClick={handleReset}
            fullWidth
            sx={{
              fontSize: '0.75rem',
              height: '32px',
            }}
          >
            Reset
          </ResetButton>
        </Tooltip>
      </Box>
      <Box mt={2}>
        {[
          { label: 'Income', value: income },
          {
            label: 'Cost of Goods Sold',
            value: costOfGoodsSold,
            isSubtraction: true,
          },
          {
            label: 'Operating Expenses',
            value: operatingExpenses,
            isSubtraction: true,
          },
          {
            label: netProfit < 0 ? 'Net Loss' : 'Net Profit',
            value: netProfit,
            isTotal: true,
          },
        ].map((item, index) => (
          <Box
            key={index}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={1}
          >
            <Typography
              variant="body1"
              fontWeight={item.isTotal ? 'bold' : 'normal'}
            >
              {item.label}
            </Typography>
            <Typography
              variant="body1"
              fontWeight={item.isTotal ? 'bold' : 'normal'}
              color={
                item.isTotal
                  ? netProfit < 0
                    ? 'error.main'
                    : 'success.main'
                  : 'text.primary'
              }
            >
              {item.isSubtraction && '-'}$
              {addCommasToNumber(Math.abs(item.value?.toFixed?.(2)))}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );

  return (
    <Container>
      {isMobile ? (
        <MobileView />
      ) : (
        <>
          <Row>
            <Label htmlFor="date-range">Date Range</Label>
            <DateRangeWrapper>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateRangePicker
                  value={[dayjs(startDate), dayjs(endDate)]}
                  onChange={handleDateChange}
                  minDate={dayjs(minDateRangeLocal)}
                  maxDate={dayjs(maxDateRangeLocal)}
                  slots={{ field: SingleInputDateRangeField }}
                  slotProps={{
                    textField: {
                      size: 'small',
                      sx: {
                        width: '100%',
                        '& input': {
                          textAlign: 'center',
                        },
                      },
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <CalendarTodayIcon />
                          </InputAdornment>
                        ),
                      },
                    },
                    field: {
                      format: 'MMMM D, YYYY',
                    },
                  }}
                />
              </LocalizationProvider>
              <Tooltip title="Reset Date Range" placement="right" arrow>
                <ResetButton
                  disabled={isResetDisabled}
                  variant="contained"
                  color="primary"
                  onClick={handleReset}
                >
                  Reset
                </ResetButton>
              </Tooltip>
            </DateRangeWrapper>
          </Row>
          <CalculationRow>
            <CalculationItem>
              <span>${addCommasToNumber(Math.abs(income?.toFixed?.(2)))}</span>
              <small>Income</small>
            </CalculationItem>
            <span className="operator">-</span>
            <CalculationItem>
              <span>
                ${addCommasToNumber(Math.abs(costOfGoodsSold?.toFixed?.(2)))}
              </span>
              <small>Cost of Goods Sold</small>
            </CalculationItem>
            <span className="operator">-</span>
            <CalculationItem>
              <span>
                ${addCommasToNumber(Math.abs(operatingExpenses?.toFixed?.(2)))}
              </span>
              <small>Operating Expenses</small>
            </CalculationItem>
            <span className="operator">=</span>
            <CalculationItem color={netProfit < 0 ? 'red' : 'green'}>
              <span>
                {netProfit < 0 ? '-$' : '$'}
                {addCommasToNumber(Math.abs(netProfit)?.toFixed?.(2))}
              </span>
              <small>Net {netProfit < 0 ? 'Loss' : 'Profit'}</small>
            </CalculationItem>
          </CalculationRow>
        </>
      )}
    </Container>
  );
};

export default ReportFilter;

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 5px;
  width: 100%;
  padding: 20px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const Label = styled.label`
  font-weight: bold;
  width: 100px;
  white-space: nowrap;
`;

const DateRangeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  justify-content: center;
`;

const CalculationRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 1.2em;
  margin-top: 20px;
  border: 1px solid ${colors.gray};
  padding: 16px 24px;
  border-radius: 20px;
  width: fit-content;
  max-width: 95%;
  margin: 0 auto;
  overflow-x: auto;
  
  > span.operator {
    font-size: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 40px;
    margin: 0 4px;
    position: relative;
    top: -8px; 
    flex-shrink: 0;
  }

  @media (max-width: 1024px) {
    padding: 12px 16px;
    gap: 4px;
  }
`;

const CalculationItem = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  color: ${(props) => props.color || 'black'};
  font-weight: bold;
  min-width: 120px;
  max-width: 180px;
  padding: 0 8px; 
  text-align: center; 
  position: relative;
  height: 60px; 

  span {
    width: 100%;
    font-size: 1.3em;
    line-height: 1.2;
    white-space: nowrap;
    text-align: center; 
    display: block;
  }
  
  small {
    width: 100%;
    font-size: 0.4em;
    color: ${colors.primary};
    text-align: center;
    font-weight: normal;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    white-space: nowrap;
  }
  @media (min-width: 768px) {
    font-size: 0.9em !important;
    min-width: 130px;
    max-width: 180px;
    margin-bottom: -3px;
  }
  @media (min-width: 1024px) {
    font-size: 1.2em !important;
    min-width: 150px;
    max-width: 200px;
  }
  @media (min-width: 1440px) {
    font-size: 1.4em !important;
    min-width: 170px;
    max-width: 220px;
  }
`;

