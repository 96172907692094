import React, { useContext, useEffect } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Link,
  LinearProgress,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PlaidLink from '../features/PlaidLink/PlaidLink';
import { colors } from '../../themes/theme';
import { WebSocketContext } from './contexts/WebSocketContext.js';
import { useNavigate } from 'react-router-dom';

const LinkBankAccountModal = ({ open, handleClose, loadingData }) => {
  const { syncing, syncCompleted } = useContext(WebSocketContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (syncCompleted) {
      navigate('/dashboard');
    }
  }, [syncCompleted, handleClose, navigate]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          padding: 2,
          borderRadius: '12px',
          backgroundColor: colors.white,
        },
      }}
      keepMounted={false}
      disableScrollLock={true}
    >
      <DialogTitle
        sx={{
          color: colors.black,
          padding: '16px 24px',
          textAlign: 'center',
          marginTop: '24px',
        }}
      >
        <Typography variant="h4">Link Your Bank Account</Typography>
      </DialogTitle>
      <DialogContent sx={{ padding: '24px', textAlign: 'center' }}>
        <Typography variant="body1" sx={{ marginBottom: '16px' }}>
          Securely connect with your bank accounts using{' '}
          <Link
            href="https://plaid.com"
            target="_blank"
            rel="noopener"
            sx={{
              color: colors.primary,
              textDecoration: 'underline',
            }}
          >
            Plaid
          </Link>
          . <br />
          <Link
            href="https://onebitapp.com"
            target="_blank"
            rel="noopener"
            sx={{
              color: colors.primary,
              textDecoration: 'underline',
            }}
          >
            ONEBIT
          </Link>{' '}
          leverages Plaid to provide insights into your business's expenses and
          transactions.
        </Typography>

        {loadingData || syncing ? (
          <>
            <Typography variant="body2" sx={{ marginTop: '16px' }}>
              {loadingData
                ? 'Linking your bank account...'
                : 'Syncing your transactions...'}
            </Typography>

            {syncing && (
              <Box sx={{ marginTop: 2, width: '100%' }}>
                <LinearProgress />
              </Box>
            )}
          </>
        ) : (
          <Box sx={{ marginTop: '16px' }}>
            <PlaidLink onSuccess={handleClose} />
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <span>
          <IconButton
            onClick={handleClose}
            disabled={syncing}
            sx={closeIconStyles}
          >
            <CloseIcon />
          </IconButton>
        </span>
      </DialogActions>
    </Dialog>
  );
};

export default LinkBankAccountModal;

const closeIconStyles = {
  position: 'absolute',
  top: 8,
  right: 8,
  color: colors.lightGray4,
  '&:hover': {
    color: colors.darkGray2,
    bgcolor: colors.lightGray2,
  },
  '&:disabled': {
    opacity: 0.5,
  },
};
