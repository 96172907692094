import React from 'react';
import Toast from '../common/ErrorToast.js';

class ErrorBoundary extends React.Component {
  state = { hasError: false, showToast: false };

  static getDerivedStateFromError(error) {
    return { hasError: true, showToast: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log('Error caught by ErrorBoundary:', error, errorInfo);
  }

  handleClose = () => {
    this.setState({ showToast: false });
  };

  handleReload = () => {
    window.location.reload();
  };

  render() {
    return (
      <>
        {this.props.children}
        {this.state.showToast && (
          <Toast
            type="error"
            onClose={this.handleClose}
            onAction={this.handleReload}
            actionText="Reload"
            title="Uh oh! Something went wrong."
            message="Please contact support@onebitapp.com for more help"
          />
        )}
      </>
    );
  }
}

export default ErrorBoundary;
