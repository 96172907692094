import { Store } from '@tanstack/store';

const store = new Store({
  transactions: [],
  accounts: [],
  categories: {
    allCategories: [],
    expenseCategories: [],
    incomeCategories: [],
    categoryIdMap: {},
  },
  user: {},
  isLoadingData: false,
  isLoadingUser: false,
  isLoadingTransactions: false,
  isLoadingAccounts: false,
  isLoadingCategories: false,
  isRowValid: true,
  dialogOpen: false,
  selectedRowId: null,
  isReceiptModalOpen: false,
  currentReceiptUrl: null,
  currentRowId: null,
  response: null,
  errorMessage: '',
  query: '',
  enableAiSearch: false,
  isAiSearchLoading: false,
  aiInterpretation: null,
  rows: [],
  minDateRange: null,
  maxDateRange: null,
  isDeleteDialogOpen: false,
  isRowEditing: false,
  rowEditCount: 0,
  linkToken: null,
  critique: null,
  aiCritiqueError: null,
  hasCritiqueAnimated: false,
  transactionType: 'All',
});

const updateStore = (data) =>
  store.setState((state) => ({
    ...state,
    ...data,
  }));

const createAlert = (severity, message) =>
  updateStore({
    alertMessage: message,
    alertSeverity: severity,
    openAlert: true,
  });

const handleCloseReceiptModal = () => {
  updateStore({
    isReceiptModalOpen: false,
    currentRowId: null,
    currentReceiptUrl: null,
  });
  setTimeout(() => {
    document.body.removeAttribute('style');
  }, 0);
};

export { store, updateStore, createAlert, handleCloseReceiptModal };
