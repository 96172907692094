export const GENERIC_SIGNUP_ERROR =
  'Something went wrong! Please contact support.';

export const FIREBASE_ERRORS = {
  'auth/invalid-credential': 'Invalid Credentials. Please try again!',
  'auth/too-many-requests': 'Too many attempts. Please try again later!',
  'auth/user-disabled': 'User is disabled. Please contact support.',
  'auth/email-already-in-use':
    'Email is already in use. Please try another email or Sign In.',
};

export const PASSWORD_CRITERIA = {
  length: 'At least 8 characters long',
  letter: 'Contains at least one letter',
  number: 'Contains at least one number',
  specialChar: 'Contains at least one special character from (!@#$%^&*)',
};

export const ALERT_POPUP_SEVERITY = {
  success: 'success',
  info: 'info',
  warning: 'warning',
  error: 'error',
};

export const DASHBOARD_ROUTES = [
  '/dashboard/chart',
  '/dashboard/datagrid',
  '/dashboard/reports',
];

export const TRANSACTIONS_QUERY_KEY = ['transactions'];
export const CATEGORIES_QUERY_KEY = ['categories'];
