import React from 'react';
import { Box, Typography, Container } from '@mui/material';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{ backgroundColor: '#1360ef', color: 'white', p: 3 }}
    >
      <Container maxWidth="lg">
        <Typography variant="body1" align="center">
          Copyright {new Date().getFullYear()} ONEBIT INC. | All Rights Reserved
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
