import React, { useState, useMemo, useCallback } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { SDataGrid } from '../../styles/style.js';
import { GridRowEditStopReasons, GridRowModes } from '@mui/x-data-grid';
import { useGridApiRef } from '@mui/x-data-grid';
import DataGridSkeleton from './DataGridSkeleton.js';
import {
  addTransaction,
  editTransaction,
  markTransactionAsRemoved,
} from '../../services/api/transactions-service.js';
import { categoriesColorArray } from '../../../themes/theme.js';
import EditToolbar from './EditToolbar.js';
import QuickSearchToolbar from './QuickSearchToolbar.js';
import DeleteConfirmationDialog from '../Delete Dialog/index.js';
import { getGridColumns } from './gridColumns.js';
import ReceiptModal from './ReceiptModal';
import MobileTransactionsPage from './MobileTransactionsPage';
import AiSearchBar from '../AiSearch';
import { useStore } from '@tanstack/react-store';
import { store, updateStore, createAlert } from '../../../data/store.js';

dayjs.extend(isBetween);
dayjs.extend(quarterOfYear);

const FullFeaturedCrudGrid = ({
  accounts,
  timePeriod,
  setOpenAlert,
  setAlertSeverity,
  setAlertMessage,
  fetchTransactions,
  onFilterChange,
  categories,
  selectedWeeks,
  selectedMonths,
  selectedQuarters,
  selectedYears,
  startDate,
  endDate,
}) => {
  const [rowModesModel, setRowModesModel] = useState({});
  const [isRowValid, setIsRowValid] = useState(true);

  const transactionType = useStore(store, (state) => state.transactionType);
  const setTransactionType = (value) => updateStore({ transactionType: value });

  const apiRef = useGridApiRef();
  const theme = useTheme();
  const isPrintMedia = useMediaQuery('print');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    rows,
    isAiSearchLoading,
    enableAiSearch,
    isReceiptModalOpen,
    isDeleteDialogOpen,
    selectedRowId,
    rowEditCount,
    isLoadingTransactions,
  } = useStore(store, (state) => ({
    rows: state.rows,
    isAiSearchLoading: state.isAiSearchLoading,
    enableAiSearch: state.enableAiSearch,
    isReceiptModalOpen: state.isReceiptModalOpen,
    isDeleteDialogOpen: state.isDeleteDialogOpen,
    currentRowId: state.currentRowId,
    currentReceiptUrl: state.currentReceiptUrl,
    selectedRowId: state.selectedRowId,
    rowEditCount: state.rowEditCount,
    isLoadingTransactions: state.isLoadingTransactions,
  }));

  const transactionCategoryColorMap = useMemo(() => {
    return categories.allCategories.reduce((acc, category, index) => {
      acc[category.value] =
        categoriesColorArray[index % categoriesColorArray.length];
      return acc;
    }, {});
  }, [categories]);

  const validateRow = (params) => {
    const hasError =
      !params.props.value ||
      params.props.value === '' ||
      params.props.value === 0;
    if (hasError && !isRowValid) {
      return { ...params.props, error: hasError };
    }
    return { ...params.props, error: false };
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel((prevRowModesModel) => {
      const newRowModesModel = {
        ...prevRowModesModel,
        [id]: { mode: GridRowModes.Edit },
      };
      updateStore({ rowEditCount: rowEditCount + 1 });
      return newRowModesModel;
    });
  };

  const handleSaveClick = async (id) => {
    setRowModesModel((prevRowModesModel) => {
      const newRowModesModel = {
        ...prevRowModesModel,
        [id]: { mode: GridRowModes.View },
      };
      if (
        prevRowModesModel[id] &&
        prevRowModesModel[id].mode !== GridRowModes.Edit
      ) {
        updateStore({ rowEditCount: rowEditCount - 1 });
      }
      return newRowModesModel;
    });
  };

  const handleProcessRowUpdate = async (updatedRow) => {
    const { id, isNew } = updatedRow;
    const rowKeys = Object.keys(updatedRow);

    let disableSave = 0;

    // Iterate through fields to make sure all values are valid before sending to server
    for (const key of rowKeys) {
      const value = updatedRow[key];
      if (value === '' || value === 0 || value === undefined) {
        setIsRowValid(false);

        // Call setEditCellValue to trigger preProcessEditCellProps mark invalid fields
        await apiRef.current.setEditCellValue({
          id: id,
          field: key,
          value: value,
        });
        disableSave++;
      }
    }
    if (disableSave) {
      createAlert(
        'warning',
        'Please make sure all necessary fields are filled out and try again.'
      );
      return;
    }
    setIsRowValid(true);

    updatedRow.category_id = categories.categoryIdMap[updatedRow.category]?.id;

    if (isNew) {
      saveNewTransaction(id, updatedRow);
    } else {
      editExistingTransaction(id, updatedRow);
    }

    const newRow = { ...updatedRow, isNew: false };
    updateStore({
      rows: rows.map((row) => (row.id === updatedRow.id ? newRow : row)),
    });
    return newRow;
  };

  const saveNewTransaction = async (id, addedRow) => {
    try {
      const addRowResponse = await addTransaction(addedRow);
      await fetchTransactions({ background: true, invalidate: true });
      if (addRowResponse.status === 200) {
        createAlert('success', 'Transaction successfully added.');
        const newId = addRowResponse.data.transaction_id;

        /**
         * Manually update `rows` state so the new row has the server-side `transaction_id`
         * for the `id` field instead of the temporary, client-generated id
         *
         * NOTE: this will log a non-breaking error since we're not using MUI's built-in premium methods
         * but it still works :)
         */

        const updatedRows = rows.map((row) =>
          row.id === id ? { ...addedRow, id: newId } : row
        );
        updateStore({
          rows: updatedRows,
          rowModesModel: {
            ...rowModesModel,
            [id]: { mode: GridRowModes.View },
          },
          rowEditCount: rowEditCount - 1,
        });
        // await fetchTransactions({ background: true });
      }
    } catch (error) {
      createAlert('error', 'Unable to add transaction. Please try again.');
      console.error(error);
    }
  };

  const editExistingTransaction = async (id, addedRow) => {
    try {
      const editRowResponse = await editTransaction(id, addedRow);
      if (editRowResponse.status === 200) {
        setRowModesModel((prevRowModesModel) => {
          const newRowModesModel = {
            ...prevRowModesModel,
            [id]: { mode: GridRowModes.View },
          };
          updateStore({ rowEditCount: rowEditCount - 1 });
          return newRowModesModel;
        });
        createAlert('success', 'Transaction successfully modified.');
        await fetchTransactions({ background: true, invalidate: true });
      }
    } catch (error) {
      createAlert('error', 'Unable to modify transaction. Please try again.');
      return;
    }
  };

  const handleDeleteClick = (id) =>
    updateStore({ selectedRowId: id, isDeleteDialogOpen: true });

  const handleConfirmDelete = async () => {
    try {
      const deleteRowResponse = await markTransactionAsRemoved(selectedRowId);
      if (deleteRowResponse.status === 200) {
        createAlert('success', 'Transaction successfully removed');
        updateStore({ rows: rows.filter((row) => row.id !== selectedRowId) });
        await fetchTransactions({ background: true, invalidate: true });
        updateStore({ selectedRowId: null });
      }
    } catch (error) {
      createAlert('error', 'Unable to remove transaction. Please try again.');
    }
  };

  const handleClose = () => updateStore({ isDeleteDialogOpen: false });

  const handleCancelClick = (id) => () => {
    setRowModesModel((prevRowModesModel) => {
      const newRowModesModel = {
        ...prevRowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      };
      updateStore({ rowEditCount: rowEditCount - 1 });
      return newRowModesModel;
    });
    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      updateStore({ rows: rows.filter((row) => row.id !== id) });
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleToggleChange = (event) => {
    const filter = event.target.value;
    setTransactionType(filter);
    onFilterChange(filter);
  };

  const handleUploadReceiptClick = (id) => {
    const row = rows.find((row) => row.id === id);
    const receiptUrl = row?.receiptUrl || null;
    updateStore({
      currentRowId: id,
      currentReceiptUrl: receiptUrl,
      isReceiptModalOpen: true,
    });
  };

  const filterRowsByPeriod = useCallback(
    (rows) => {
      let filteredRows = [];

      switch (timePeriod) {
        case 'week':
          filteredRows = rows.filter((row) => {
            if (row.isNew) return true;
            const rowDate = dayjs(row.date);
            return selectedWeeks.some((week) => {
              const [start, end] = week.split(' - ');
              const startOfSelectedWeek = dayjs(
                `${start}, ${end.split(', ')[1]}`
              ).startOf('day');
              const endOfSelectedWeek = dayjs(end).endOf('day');
              return rowDate.isBetween(
                startOfSelectedWeek,
                endOfSelectedWeek,
                null,
                '[]'
              );
            });
          });
          break;
        case 'month':
          filteredRows = rows.filter((row) => {
            if (row.isNew) return true;
            const rowDate = dayjs(row.date).format('MMMM YYYY');
            return selectedMonths.includes(rowDate);
          });
          break;
        case 'quarter':
          filteredRows = rows.filter((row) => {
            if (row.isNew) return true;
            const date = dayjs(row.date);
            const quarter = `Q${date.quarter()} ${date.format('YYYY')}`;
            return selectedQuarters.includes(quarter);
          });
          break;
        case 'year':
          filteredRows = rows.filter((row) => {
            if (row.isNew) return true;
            const rowDate = dayjs(row.date).format('YYYY');
            return selectedYears.includes(rowDate);
          });
          break;
        case 'all':
          const start = dayjs(startDate).startOf('day');
          const end = dayjs(endDate).endOf('day');
          filteredRows = rows.filter((row) => {
            if (row.isNew) return true;
            const rowDate = dayjs(row.date);
            return rowDate.isBetween(start, end, null, '[]');
          });
          break;
        default:
          filteredRows = rows;
          break;
      }

      // Filter by transactionType
      return filteredRows.filter((row) => {
        if (transactionType?.toLowerCase() === 'income') {
          return row.amount >= 0;
        } else if (transactionType?.toLowerCase() === 'expenses') {
          return row.amount <= 0;
        }
        return true;
      });
    },
    [
      timePeriod,
      transactionType,
      selectedWeeks,
      selectedMonths,
      selectedQuarters,
      selectedYears,
      startDate,
      endDate,
    ]
  );
  const filteredRows = useMemo(
    () => filterRowsByPeriod(rows),
    [rows, filterRowsByPeriod]
  );

  const columns = getGridColumns({
    accounts,
    handleEditClick,
    handleSaveClick,
    handleCancelClick,
    handleDeleteClick,
    handleUploadReceiptClick,
    rowModesModel,
    validateRow,
    isPrintMedia,
    transactionCategoryColorMap,
    categories,
  });

  if (isMobile) {
    return (
      <MobileTransactionsPage
        rows={rows}
        accounts={accounts}
        categories={categories}
        fetchTransactions={fetchTransactions}
        setAlertMessage={setAlertMessage}
        setAlertSeverity={setAlertSeverity}
        setOpenAlert={setOpenAlert}
        timePeriod={timePeriod}
        selectedWeeks={selectedWeeks}
        selectedMonths={selectedMonths}
        selectedQuarters={selectedQuarters}
        selectedYears={selectedYears}
        startDate={startDate}
        endDate={endDate}
        transactionCategoryColorMap={transactionCategoryColorMap}
        onFilterChange={onFilterChange}
      />
    );
  }

  return (
    <>
      <Box
        sx={{
          minHeight: 'calc(100vh - 150px)',
          maxHeight: '100%',
          width: { xs: '95vw', sm: '95vw', md: '85vw', lg: '80vw' },
          position: 'relative',
          mb: '100px',
        }}
      >
        {isDeleteDialogOpen && (
          <DeleteConfirmationDialog
            isOpen={isDeleteDialogOpen}
            onClose={handleClose}
            onConfirm={handleConfirmDelete}
          />
        )}
        <SDataGrid
          ignoreDiacritics
          apiRef={apiRef}
          rows={isLoadingTransactions || isAiSearchLoading ? [] : filteredRows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={(updatedRow) => handleProcessRowUpdate(updatedRow)}
          onProcessRowUpdateError={(error) => console.log(error)}
          loading={isLoadingTransactions || isAiSearchLoading}
          slots={{
            loadingOverlay: DataGridSkeleton,
            toolbar: React.memo(() => (
              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    gap: 20,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                    className="no-print"
                  >
                    <EditToolbar
                      setRowModesModel={setRowModesModel}
                      onFilterChange={onFilterChange}
                      handleToggleChange={handleToggleChange}
                    />
                  </div>
                  {enableAiSearch ? (
                    <AiSearchBar
                      fetchTransactions={fetchTransactions}
                      onFilterChange={onFilterChange}
                    />
                  ) : (
                    <QuickSearchToolbar />
                  )}
                </div>
              </>
            )),
          }}
          slotProps={{
            toolbar: {
              setRows: (rows) => updateStore({ rows }),
              setRowModesModel,
            },
          }}
        />
      </Box>
      {isReceiptModalOpen && (
        <ReceiptModal
          open={isReceiptModalOpen}
          fetchTransactions={fetchTransactions}
        />
      )}
    </>
  );
};

export default FullFeaturedCrudGrid;
