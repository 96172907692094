import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

const SkeletonRow = () => {
  return (
    <Stack
      direction="column"
      sx={{
        width: 'min-content',
      }}
    >
      {[...Array(5)].map((_, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          sx={{ my: 2, mx: 2 }}
          width="calc(80vw - 32px)"
          height={40}
        />
      ))}
    </Stack>
  );
};

const DataGridSkeleton = () => {
  return (
    <Box
      sx={{
        height: 200,
      }}
    >
      {[...Array(50)].map((_, index) => (
        <SkeletonRow key={index} />
      ))}
    </Box>
  );
};

export default DataGridSkeleton;
