import React from 'react';
import styled from '@emotion/styled';
import { Typography, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { colors } from '../../themes/theme';

const ToastContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: ${(props) => {
    switch (props.type) {
      case 'error':
        return colors.ebbackground;
      case 'alert':
        return colors.warningLight;
      case 'info':
        return colors.infoLight;
      default:
        return colors.white;
    }
  }};
  border-left: 4px solid
    ${(props) => {
      switch (props.type) {
        case 'error':
          return colors.error;
        case 'alert':
          return colors.warning;
        case 'info':
          return colors.info;
        default:
          return colors.primary;
      }
    }};
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  width: 500px;
  z-index: 9999;
  padding: 20px 20px;
  padding-right: 50px;
`;

const IconWrapper = styled.div`
  color: ${(props) => {
    switch (props.type) {
      case 'error':
        return colors.error;
      case 'alert':
        return colors.warning;
      case 'info':
        return colors.info;
      default:
        return colors.primary;
    }
  }};
  margin-right: 16px;
  display: flex;
  align-items: center;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
`;

const StyledButton = styled(Button)`
  color: ${colors.primary};
  font-weight: bold;
  border: 1px solid ${colors.primary};
  padding: 4px 12px;
  &:hover {
    background-color: rgba(19, 96, 239, 0.08);
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;

const ErrorToast = ({
  type = 'none',
  onClose,
  onAction,
  actionText,
  title,
  message,
}) => {
  const getIcon = () => {
    switch (type) {
      case 'error':
        return <ErrorOutlineIcon style={{ fontSize: '28px' }} />;
      case 'alert':
        return <WarningAmberIcon style={{ fontSize: '28px' }} />;
      case 'info':
        return <InfoOutlinedIcon style={{ fontSize: '28px' }} />;
      default:
        return null;
    }
  };

  return (
    <ToastContainer type={type}>
      <IconWrapper type={type}>{getIcon()}</IconWrapper>
      <MessageContainer>
        <Typography
          variant="body2"
          style={{ fontWeight: 'bold', fontSize: '16px' }}
        >
          {title || 'Notification'}
        </Typography>
        <Typography
          variant="caption"
          style={{ fontSize: '12px', marginTop: '8px' }}
        >
          {message || 'This is a notification message.'}
        </Typography>
      </MessageContainer>
      {actionText && onAction && (
        <ActionContainer>
          <StyledButton size="small" onClick={onAction}>
            {actionText}
          </StyledButton>
        </ActionContainer>
      )}
      <CloseButtonWrapper>
        <IconButton size="small" aria-label="close" onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </CloseButtonWrapper>
    </ToastContainer>
  );
};

export default ErrorToast;
