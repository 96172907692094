import React, { useContext, useState } from 'react';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import ProfileInfo from './ProfileInfo';
import BankAccountInfo from './BankAccountInfo';
import { colors } from '../../../../themes/theme';
import { AuthContext } from '../../../../components/common/contexts/authContext';
import { AccountCircle } from '@mui/icons-material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const SettingsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoggedIn } = useContext(AuthContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [selectedTab, setSelectedTab] = useState(() => {
    if (location.pathname.includes('profile')) return 0;
    if (location.pathname.includes('bank-account')) return 1;
    return 0;
  });

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    if (newValue === 0) {
      navigate('/settings/profile');
    } else if (newValue === 1) {
      navigate('/settings/bank-account');
    }
  };

  const getPageTitle = () => {
    switch (location.pathname) {
      case '/settings/profile':
        return 'Profile Information';
      case '/settings/bank-account':
        return 'Bank Accounts';
      default:
        return 'Settings';
    }
  };

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        minHeight: '100vh',
        backgroundColor: colors.lightGray,
        padding: '20px',
      }}
    >
      {!isSmallScreen && (
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={selectedTab}
          onChange={handleTabChange}
          sx={{
            borderRight: 1,
            borderColor: 'divider',
            minWidth: '250px',
            backgroundColor: colors.lightGray,
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
            borderRadius: '10px',
            marginRight: '10px',
            marginLeft: '-10px',
          }}
        >
          <Tab
            icon={<AccountCircle fontSize="large" />}
            iconPosition="start"
            label="Profile"
            sx={{
              justifyContent: 'flex-start',
              textTransform: 'none',
              fontSize: '1rem',
              color:
                selectedTab === 0 ? `${colors.white} !important` : colors.black,
              backgroundColor:
                selectedTab === 0 ? colors.primaryHover : 'inherit',
              gap: 1,
              '&:hover': {
                backgroundColor:
                  selectedTab === 0 ? colors.primary : colors.gray,
                color: selectedTab === 0 ? colors.white : colors.black,
              },
              marginBottom: 2,
            }}
          />
          <Tab
            icon={<AccountBalanceIcon fontSize="large" />}
            iconPosition="start"
            label="Bank Account"
            sx={{
              justifyContent: 'flex-start',
              textTransform: 'none',
              fontSize: '1rem',
              color:
                selectedTab === 1 ? `${colors.white} !important` : colors.black,
              backgroundColor:
                selectedTab === 1 ? colors.primaryHover : 'inherit',
              gap: 1,
              '&:hover': {
                backgroundColor:
                  selectedTab === 1 ? colors.primary : colors.gray,
                color: selectedTab === 1 ? colors.white : colors.black,
              },
              marginBottom: 2,
            }}
          />
        </Tabs>
      )}
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: colors.white,
          borderRadius: '10px',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          padding: '20px',
          marginLeft: isSmallScreen ? 0 : '10px',
          width: isSmallScreen ? '100%' : 'calc(100% - 250px)',
        }}
      >
        {location.pathname.startsWith('/settings') && (
          <Typography variant="h4" gutterBottom>
            {getPageTitle()}
          </Typography>
        )}
        <Routes>
          <Route path="/" element={<Navigate to="profile" />} />
          <Route path="profile" element={<ProfileInfo />} />
          <Route path="bank-account" element={<BankAccountInfo />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default SettingsPage;
