import React, { useMemo, useCallback } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import {
  DesktopDateRangePicker,
  MobileDateRangePicker,
} from '@mui/x-date-pickers-pro';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { IconButton, useMediaQuery, Button, Tooltip } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import dayjs from 'dayjs';
import { colors } from '../../../themes/theme';

const DateRangeComponent = React.memo(
  ({
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    minDateRange,
    maxDateRange,
    handleReset,
    isResetDisabled,
    onDateChange,
  }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    // There's an awkward range between 900px and 970px where the reset button gets cut off when placed next to the date range picker. This happens just before the md breakpoint styles are applied.
    const isBetween900And970 = useMediaQuery(
      '(min-width:900px) and (max-width:970px)'
    );

    const handleDateChange = useCallback(
      (newValue) => {
        if (newValue[0]?.isValid() && newValue[1]?.isValid()) {
          const newStartDate = newValue[0].format('YYYY-MM-DD');
          const newEndDate = newValue[1].format('YYYY-MM-DD');
          setStartDate(newStartDate);
          setEndDate(newEndDate);
          onDateChange(newStartDate, newEndDate);
        }
      },
      [setStartDate, setEndDate, onDateChange]
    );

    const dateRangePickerProps = useMemo(
      () => ({
        value: [dayjs(startDate), dayjs(endDate)],
        onChange: handleDateChange,
        minDate: dayjs(minDateRange),
        maxDate: dayjs(maxDateRange),
        slots: {
          field: SingleInputDateRangeField,
          openPickerIcon: () => null,
        },
        slotProps: {
          field: { format: 'M/D/YY' },
          textField: {
            size: 'small',
            sx: getTextFieldStyles(isBetween900And970),
          },
        },
      }),
      [
        startDate,
        endDate,
        minDateRange,
        maxDateRange,
        handleDateChange,
        isBetween900And970,
      ]
    );

    const { Container, ResetComponent, resetContent } = useMemo(
      () => ({
        Container: isBetween900And970 ? Column : Row,
        ResetComponent: isBetween900And970 ? ResetTextButton : ResetIconButton,
        resetContent: isBetween900And970 ? (
          'Reset'
        ) : (
          <RestartAltIcon fontSize="small" />
        ),
      }),
      [isBetween900And970]
    );

    return (
      <Container>
        <Tooltip title="Enter dates or select range" arrow placement="top">
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {isSmallScreen ? (
                <MobileDateRangePicker {...dateRangePickerProps} />
              ) : (
                <DesktopDateRangePicker {...dateRangePickerProps} />
              )}
            </LocalizationProvider>
          </div>
        </Tooltip>
        <ResetComponent
          disabled={isResetDisabled}
          onClick={handleReset}
          size="small"
          aria-label="Reset date range"
        >
          {resetContent}
        </ResetComponent>
      </Container>
    );
  }
);

export default DateRangeComponent;

// Styled Components
const Row = styled('div')({ display: 'flex', alignItems: 'center' });
const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const ResetTextButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  padding: 0,
  margin: 0,
  minWidth: 'auto',
  backgroundColor: 'transparent',
  color: colors.primary,
  fontSize: '0.65rem',
  lineHeight: 1.2,
  '&:hover': {
    backgroundColor: 'transparent',
    textDecoration: 'underline',
  },
  '&:disabled': {
    color: theme.palette.action.disabled,
  },
}));

const ResetIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: colors.secondary,
  color: colors.white,
  padding: '6px',
  marginLeft: '4px',
  '&:hover': {
    backgroundColor: colors.hoverBg,
  },
  '&:disabled': {
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled,
  },
}));

// Function to generate dynamic styles for the text field based on screen width
const getTextFieldStyles = (isBetween900And970) => ({
  width: 'auto',
  minWidth: isBetween900And970 ? '128px' : '148px',
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'white',
    borderRadius: isBetween900And970 ? '30px' : '15px',
    overflow: 'hidden',
  },
  '& .MuiInputBase-input': {
    fontSize: isBetween900And970
      ? '0.75rem'
      : { xs: '0.75rem', sm: '0.8rem', md: '0.85rem' },
    padding: isBetween900And970 ? '2px' : { xs: '3px', sm: '4px', md: '5px' },
  },
});
