import Signup from '../Register/Signup.js';
import Signin from '../Login/Signin';
import React, { useState, useContext } from 'react';
import './LandingPage.css';
import { useNavigate } from 'react-router-dom';
import {
  FIREBASE_ERRORS,
  GENERIC_SIGNUP_ERROR,
} from '../../../data/constants.js';
import { AuthContext } from '../../common/contexts/authContext.js';

const LandingPage = () => {
  const [showSignIn, setShowSignIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { login, register } = useContext(AuthContext);
  const navigate = useNavigate();

  const [user, setUser] = useState({
    emailId: '',
    password: '',
  });

  const [registerUser, setRegisterUser] = useState({
    firstName: '',
    lastName: '',
    emailId: '',
    password: '',
    confirmPassword: '',
  });

  const checkConfirmPasswordMatch = (registerUser) =>
    !(registerUser.password !== registerUser.confirmPassword);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!showSignIn) {
      try {
        if (!checkConfirmPasswordMatch(registerUser)) {
          throw new Error('Passwords do not match. Please try again.');
        }

        await register(registerUser);
      } catch (error) {
        console.error('Error during registration:', error);

        if (error.code === 'auth/email-already-in-use') {
          setShowSignIn(true);
          setUser({ ...user, emailId: registerUser.emailId });
          setErrorMessage(
            `An account associated with this email already exists. Please sign in.`
          );
        } else {
          setErrorMessage(`Unable to register user! Please try again.`);
        }
      }
    } else {
      try {
        const loginReq = {
          emailId: registerUser?.emailId || user.emailId,
          password: registerUser?.password || user.password,
        };
        await login(loginReq);
      } catch (error) {
        const errorMessage =
          FIREBASE_ERRORS[error?.code] || GENERIC_SIGNUP_ERROR;
        setErrorMessage(errorMessage);
      }
    }
  };

  return showSignIn ? (
    <Signin
      handleSubmit={handleSubmit}
      user={user}
      setUser={setUser}
      handleSignUpClick={() => {
        setShowSignIn(false);
        setErrorMessage('');
      }}
      handleResetPasswordClick={() => navigate('/login/reset')}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    />
  ) : (
    <Signup
      handleSubmit={handleSubmit}
      registerUser={registerUser}
      setRegisterUser={setRegisterUser}
      handleSignInClick={() => {
        setShowSignIn(true);
        setErrorMessage('');
      }}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    />
  );
};

export default LandingPage;
