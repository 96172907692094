import React, { createContext, useState, useEffect, useCallback } from 'react';
import userService from '../../services/api/user-service';
import firebaseAuth from '../../services/api/firebase-service';
import axiosInstance from '../../../api/axiosWrapper';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [requireAuth, setRequireAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // Check session login status
  const checkSessionLogin = useCallback(async () => {
    if (window.location.pathname === '/login/reset') {
      setRequireAuth(true);
      setLoading(false);
      return;
    }
    try {
      const response = await axiosInstance.post('/auth', {});
      if (response.status === 200) {
        setIsLoggedIn(true);
        setCurrentUser(response.data.user);
      }
    } catch (error) {
      console.error('User is not logged in', error);
      setIsLoggedIn(false);
      setCurrentUser(null);
    } finally {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    checkSessionLogin();
  }, [checkSessionLogin]);

  // Register function
  const register = async (registerUser) => {
    try {
      // Create Firebase user
      await firebaseAuth.createFirebaseUser(
        registerUser.emailId,
        registerUser.password
      );
      const firebaseToken = await firebaseAuth.getFirebaseIdToken();
      const firebaseUID = await firebaseAuth.getFirebaseUID();

      // Register the user in backend
      const newUserResponse = await userService.registerUser(
        registerUser,
        firebaseUID,
        firebaseToken
      );
      console.log('Backend registration response:', newUserResponse);
      const newUser = newUserResponse.registeredUser;

      // Automatically log in user after registration
      const loginRes = await firebaseAuth.firebaseEmailPassLogin({
        emailId: registerUser.emailId,
        password: registerUser.password,
      });

      if (loginRes.status === 200) {
        setCurrentUser(newUser);
        setIsLoggedIn(true);
        navigate('/link-bank-account');
        return newUser;
      }
    } catch (error) {
      console.error('Registration Error:', error);
      throw error;
    }
  };

  const login = async (loginReq) => {
    try {
      const loginRes = await firebaseAuth.firebaseEmailPassLogin(loginReq);

      if (loginRes.status === 200) {
        const firebaseToken = await firebaseAuth.getFirebaseIdToken();

        // Fetch user details from the backend using the Firebase token
        const user = await userService.getUserByToken(firebaseToken);
        setCurrentUser(user);
        setIsLoggedIn(true);
        navigate('/dashboard');
        return user;
      }
    } catch (error) {
      console.error('Login Error:', error);
      throw error;
    }
  };

  const handleLogout = async () => {
    try {
      const response = await axiosInstance.post('/logoutUser');
      if (response.status === 200) {
        setCurrentUser(null);
        setIsLoggedIn(false);
        queryClient.clear();
        navigate('/');
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        requireAuth,
        isLoggedIn,
        currentUser,
        setCurrentUser,
        login,
        register,
        handleLogout,
        loading,
      }}
    >
      {!loading ? (
        children
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </AuthContext.Provider>
  );
};
