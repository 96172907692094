import * as React from 'react';
import SlideTransition from './SlideTransition';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';

export default function AlertPopup(props) {
  const { open, setOpen, alertMessage, alertSeverity } = props;
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ width: '100%', position: 'absolute' }}>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        onClose={handleClose}
        autoHideDuration={6000}
        TransitionComponent={SlideTransition}
      >
        <Alert
          severity={alertSeverity ?? 'info'}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
