// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  margin: 0;
  overflow-x: hidden;
}

html {
  background-color: #1360ef;
}

@media print {
  .no-print {
    display: none !important;
  }

  .print-cell-fw {
    width: 200px !important;
  }
}

.MuiMenu-list {
  padding: 8px !important;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE;IACE,wBAAwB;EAC1B;;EAEA;IACE,uBAAuB;EACzB;AACF;;AAEA;EACE,uBAAuB;EACvB,eAAe;AACjB","sourcesContent":["* {\n  margin: 0px;\n  padding: 0px;\n  box-sizing: border-box;\n}\n\nbody {\n  margin: 0;\n  overflow-x: hidden;\n}\n\nhtml {\n  background-color: #1360ef;\n}\n\n@media print {\n  .no-print {\n    display: none !important;\n  }\n\n  .print-cell-fw {\n    width: 200px !important;\n  }\n}\n\n.MuiMenu-list {\n  padding: 8px !important;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
