import React, { useState, useEffect, useMemo } from 'react';
import {
  Modal,
  Box,
  TextField,
  Button,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'background.paper',
  p: 4,
  borderRadius: '8px',
};

const TrxModal = ({
  open,
  handleClose,
  transaction,
  onSave,
  onDelete,
  categories,
  categoryGroups,
}) => {
  const [formData, setFormData] = useState({
    date: dayjs().format('YYYY-MM-DD'),
    description: '',
    amount: '',
    account: 'Other',
    category: '',
  });
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  useEffect(() => {
    if (transaction) {
      setFormData({
        date: transaction.date || dayjs().format('YYYY-MM-DD'),
        description: transaction.description || '',
        amount: transaction.amount || '',
        account: 'Other',
        category: transaction.category || '',
      });
    } else {
      setFormData({
        date: dayjs().format('YYYY-MM-DD'),
        description: '',
        amount: '',
        account: 'Other',
        category: '',
      });
    }
  }, [transaction]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, date: dayjs(date).format('YYYY-MM-DD') });
  };

  const handleSubmit = () => {
    if (!formData.description || formData.amount === '' || !formData.category) {
      alert('Please fill out all fields.');
      return;
    }
    onSave(formData);
  };

  const handleDeleteClick = () => {
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = () => {
    setDeleteConfirmOpen(false);
    onDelete(transaction.id);
    handleClose();
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
  };

  const categoryOptions = useMemo(() => {
    const amount = parseFloat(formData.amount);
    const groupKey = amount >= 0 ? 'income' : 'expense';

    if (!categoryGroups || !categoryGroups[groupKey]) {
      console.error('Category groups not properly defined:', categoryGroups);
      return [];
    }

    return categoryGroups[groupKey].flatMap((group) => [
      { value: `__group_${group.title}`, label: group.title, disabled: true },
      ...group.categories.map((category) => ({
        value: category,
        label: category,
      })),
    ]);
  }, [categoryGroups, formData.amount]);

  useEffect(() => {
    const amount = parseFloat(formData.amount);
    const groupKey = amount >= 0 ? 'income' : 'expense';
    const validCategories = categoryGroups[groupKey].flatMap(
      (group) => group.categories
    );

    if (!validCategories.includes(formData.category)) {
      setFormData((prev) => ({ ...prev, category: '' }));
    }
  }, [formData.amount, formData.category, categoryGroups]);

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            {transaction ? 'Edit Transaction' : 'Add Transaction'}
          </Typography>
          <MobileDatePicker
            label="Date"
            value={dayjs(formData.date)}
            onChange={handleDateChange}
            renderInput={(params) => (
              <TextField fullWidth margin="normal" {...params} />
            )}
          />
          <TextField
            name="description"
            label="Description"
            fullWidth
            margin="normal"
            value={formData.description}
            onChange={handleChange}
          />
          <TextField
            name="amount"
            label="Amount"
            fullWidth
            margin="normal"
            type="number"
            value={formData.amount}
            onChange={handleChange}
          />
          <TextField
            name="account"
            label="Account"
            fullWidth
            margin="normal"
            value={formData.account}
            InputProps={{
              readOnly: true,
            }}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="category-select-label">Category</InputLabel>
            <Select
              labelId="category-select-label"
              id="category-select"
              name="category"
              value={formData.category}
              onChange={handleChange}
              label="Category"
            >
              {categoryOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  disabled={option.disabled}
                  sx={
                    option.disabled
                      ? { fontWeight: 'bold', color: 'primary.main' }
                      : {}
                  }
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              sx={{ flex: 1, mr: 1 }}
            >
              Save
            </Button>
            {transaction && (
              <Button
                variant="contained"
                color="error"
                onClick={handleDeleteClick}
                sx={{ flex: 1, ml: 1 }}
              >
                Delete
              </Button>
            )}
          </Box>
        </Box>
      </Modal>

      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Confirm Delete'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this transaction? This action cannot
            be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TrxModal;
