import { useState } from 'react';

export const useDateState = () => {
  const [isOpenWeek, setIsOpenWeek] = useState(false);
  const [isOpenMonth, setIsOpenMonth] = useState(false);
  const [isOpenQuarter, setIsOpenQuarter] = useState(false);
  const [isOpenYear, setIsOpenYear] = useState(false);

  const handleOverWeek = () => setIsOpenWeek(true);
  const handleCloseWeek = () => setIsOpenWeek(false);

  const handleOverMonth = () => setIsOpenMonth(true);
  const handleCloseMonth = () => setIsOpenMonth(false);

  const handleOverQuarter = () => setIsOpenQuarter(true);
  const handleCloseQuarter = () => setIsOpenQuarter(false);

  const handleOverYear = () => setIsOpenYear(true);
  const handleCloseYear = () => setIsOpenYear(false);

  return {
    isOpenWeek,
    setIsOpenWeek,
    handleOverWeek,
    handleCloseWeek,
    isOpenMonth,
    setIsOpenMonth,
    handleOverMonth,
    handleCloseMonth,
    isOpenQuarter,
    setIsOpenQuarter,
    handleOverQuarter,
    handleCloseQuarter,
    isOpenYear,
    setIsOpenYear,
    handleOverYear,
    handleCloseYear,
  };
};
