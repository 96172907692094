import React, { memo } from 'react';
import { DGExportButton } from '../../styles/style.js';
import { SButton } from '../../styles/style.js';
import { randomId } from '@mui/x-data-grid-generator';
import PlaidLink from '../PlaidLink/PlaidLink.js';
import AddIcon from '@mui/icons-material/Add';
import { GridRowModes } from '@mui/x-data-grid';
import {
  Box,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { CustomGridToolbarContainer } from '../../styles/style.js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useDashboardContext } from '../../common/contexts/DashboardContext.js';
import TransactionFilter from './transactionFilter.js';
import ToggleAiSearch from '../AiSearch/ToggleAiSearch.js';
import { store, updateStore } from '../../../data/store.js';
import { useStore } from '@tanstack/react-store';
dayjs.extend(utc);

const EditToolbar = ({ setRowModesModel, onFilterChange }) => {
  const {
    setActiveTimeTab,
    setSelectedWeeks,
    setSelectedMonths,
    setSelectedQuarters,
    setSelectedYears,
    setStartDate,
    setEndDate,
    minDateRangeLocal,
    maxDateRangeLocal,
  } = useDashboardContext();

  const { rows, rowEditCount, isLoadingTransactions } = useStore(
    store,
    (state) => ({
      rows: state.rows,
      rowEditCount: state.rowEditCount,
      isLoadingTransactions: state.isLoadingTransactions,
    })
  );

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const setTransactionType = (value) => updateStore({ transactionType: value });

  const handleClick = async () => {
    if (isLoadingTransactions) {
      return;
    }

    updateStore({ isLoadingTransactions: true });

    const hasUnsaved = rows.some((row) => row.isNew);
    if (hasUnsaved) {
      return updateStore({ rows, isLoadingTransactions: false });
    }

    const id = randomId();
    const localDate = new Date();
    const utcDate = dayjs(localDate).utc().format();

    const newRow = {
      id,
      date: utcDate,
      description: '',
      account: 'Other',
      category: '',
      amount: 0,
      isNew: true,
    };
    setActiveTimeTab('all');
    setTransactionType('All');
    setSelectedWeeks([]);
    setSelectedMonths([]);
    setSelectedQuarters([]);
    setSelectedYears([]);
    setStartDate(dayjs(minDateRangeLocal).format('YYYY-MM-DD'));
    setEndDate(dayjs(maxDateRangeLocal).format('YYYY-MM-DD'));
    onFilterChange('All');

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'date' },
    }));
    updateStore({
      rowEditCount: rowEditCount + 1,
      isLoadingTransactions: false,
      rows: [newRow, ...rows],
    });
  };

  const mobileLayout = (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
    >
      <Typography
        variant="h6"
        sx={{
          width: '100%',
          mb: 2,
          textAlign: 'center',
        }}
      >
        Transactions
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          mb: 2,
        }}
      >
        <PlaidLink />
        <DGExportButton
          printOptions={{
            print: true,
            fields: ['date', 'account', 'category', 'amount'],
            hideFooter: true,
            hideToolbar: true,
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: 2,
        }}
      >
        <ToggleAiSearch />
        <TransactionFilter onFilterChange={onFilterChange} />
        <Tooltip title="Add Transaction" placement="top" arrow>
          <SButton
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleClick}
            sx={{ height: '40px', width: '100%' }}
          >
            Add Transaction
          </SButton>
        </Tooltip>
      </Box>
    </Box>
  );

  const desktopLayout = (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 'auto' }}>
        <Typography variant="h6" sx={{ mr: 2 }}>
          Transactions
        </Typography>
        <TransactionFilter onFilterChange={onFilterChange} />
      </Box>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <ToggleAiSearch />
        <Tooltip title="Add Transaction" placement="top" arrow>
          <SButton
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleClick}
            sx={{ width: '180px' }}
          >
            Add Transaction
          </SButton>
        </Tooltip>
        <PlaidLink />
        <DGExportButton
          printOptions={{
            print: true,
            fields: ['date', 'account', 'category', 'amount'],
            hideFooter: true,
            hideToolbar: true,
          }}
        />
      </Box>
    </Box>
  );

  return (
    <CustomGridToolbarContainer sx={{ width: '100%', padding: '16px' }}>
      {isDesktop ? desktopLayout : mobileLayout}
    </CustomGridToolbarContainer>
  );
};

export default memo(EditToolbar);
