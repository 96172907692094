import React from 'react';
import ChartHomePage from '../../../components/features/Data Visualizations';
import { useDashboardContext } from '../../../components/common/contexts/DashboardContext';

const ChartPage = () => {
  const {
    activeTimeTab,
    selectedWeeks,
    selectedMonths,
    selectedQuarters,
    selectedYears,
    startDate,
    endDate,
  } = useDashboardContext();

  return (
    <ChartHomePage
      activeTimeTab={activeTimeTab}
      selectedWeeks={selectedWeeks}
      selectedMonths={selectedMonths}
      selectedQuarters={selectedQuarters}
      selectedYears={selectedYears}
      startDate={startDate}
      endDate={endDate}
    />
  );
};

export default ChartPage;
