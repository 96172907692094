import React, { createContext, useState, useEffect, useRef } from 'react';

export const WebSocketContext = createContext(null);

// WebSocket Provider component
export const WebSocketProvider = ({ wsUrl, children }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [syncing, setSyncing] = useState(false);
  const [syncCompleted, setSyncCompleted] = useState(false);
  const wsRef = useRef(null);

  useEffect(() => {
    if (!wsUrl) return;

    const connectWebSocket = () => {
      const ws = new WebSocket(wsUrl);

      ws.onopen = () => {
        setIsConnected(true);
      };

      ws.onmessage = (event) => {
        const message = JSON.parse(event.data);

        if (message.status === 'syncing') {
          setSyncing(true);
          setSyncCompleted(false);
        } else if (message.status === 'complete') {
          setSyncing(false);
          setSyncCompleted(true);
        }
      };

      ws.onclose = () => {
        setIsConnected(false);
        setTimeout(() => connectWebSocket(), 5000);
      };

      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      wsRef.current = ws;
    };

    connectWebSocket();

    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, [wsUrl]);

  return (
    <WebSocketContext.Provider
      value={{ isConnected, syncing, syncCompleted, wsRef }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
