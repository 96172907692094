import { styled } from '@mui/system';
import Button from '@mui/material/Button';

export const SButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#1360EF',
  fontSize: '1rem',
  paddingTop: theme.spacing(1.4),
  paddingBottom: theme.spacing(1.4),
  textTransform: 'none',
  fontWeight: 'bold',
}));
