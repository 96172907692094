import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyCheck } from '@fortawesome/free-solid-svg-icons';
import './PosSelectionPage.css';

const PosSelectionPage = () => {
  let navigate = useNavigate();
  const [posSystem, setPosSystem] = React.useState({
    pos: '',
  });

  const handleChange = (e) => {
    console.log(e.target.value);
    setPosSystem(e.target.value);
  };

  const handlePosSelectionSubmit = async (e) => {
    e.preventDefault();
    console.log({ posSystem });
    navigate('/setbank');
    // const response  = await fetch('http://localhost:8080/addPosSystem', {
    //   mode: 'no-cors',
    //   method: 'post',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({
    //     posSystem: posSystem
    //   })
    // }).then(response => response.json())
    // console.log(response);
    //navigate('/dashboard');
  };

  return (
    <div className="landing-col">
      <div className="landing-form-container">
        <FontAwesomeIcon
          icon={faMoneyCheck}
          style={{ color: '#e1325a', height: '40px', marginBottom: '20px' }}
        />
        <form id="PosSelectionForm" onSubmit={handlePosSelectionSubmit}>
          <div className="form-heading"> Select your POS System </div>
          <div>
            <input
              type="radio"
              className="radioButton"
              id="square"
              name="choose"
              value="square"
              checked={posSystem === 'square'}
              onChange={handleChange}
            />
            <label htmlFor="square">Square</label>
          </div>

          <div>
            <input
              type="radio"
              className="radioButton"
              id="clover"
              name="choose"
              value="clover"
              onChange={handleChange}
              checked={posSystem === 'clover'}
            />
            <label htmlFor="clover">Clover</label>
          </div>

          <button type="submit" className="login-btn">
            Continue
          </button>
        </form>
      </div>
    </div>
  );
};

export default PosSelectionPage;
