import { useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchCategories } from '../../services/api/transactions-service';
import { updateStore } from '../../../data/store';
import { useEffect } from 'react';
import { CATEGORIES_QUERY_KEY } from '../../../data/constants';

const useCategories = () => {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: CATEGORIES_QUERY_KEY,
    queryFn: fetchCategories,
    onSuccess: (response) => {
      updateStore({
        categories: {
          allCategories: response?.data?.categories || [],
          expenseCategories: response?.data?.expenseCategories || [],
          incomeCategories: response?.data?.incomeCategories || [],
          categoryIdMap: response?.data?.categories?.reduce((acc, category) => {
            acc[category.value] = category;
            return acc;
          }, {}),
        },
        isLoadingCategories: false,
      });
    },
    staleTime: Infinity,
    cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    if (query.data) {
      updateStore({
        categories: {
          allCategories: query.data?.data?.categories || [],
          expenseCategories: query.data?.data?.expenseCategories || [],
          incomeCategories: query.data?.data?.incomeCategories || [],
          categoryIdMap: query.data?.data?.categories?.reduce(
            (acc, category) => {
              acc[category.value] = category;
              return acc;
            },
            {}
          ),
        },
        isLoadingCategories: false,
      });
    }
  }, [query.data]);

  return {
    ...query,
    refetchCategories: () =>
      queryClient.invalidateQueries({
        queryKey: [CATEGORIES_QUERY_KEY],
        exact: true,
      }),
  };
};

export default useCategories;
