import React from 'react';
import {
  TabularContainer,
  SectionHeader,
  FlexItem,
  LineItemContainer,
  TotalLineContainer,
} from './styled';

import { addCommasToNumber } from './addCommasToNumber';

const FinancialStatement = ({
  incomeData,
  costOfGoodsSoldData,
  operatingExpensesData,
  totalIncome,
  totalCostOfGoodsSold,
  grossProfit,
  grossProfitPercentage,
  totalOperatingExpenses,
  netProfit,
  netProfitPercentage,
}) => {
  return (
    <TabularContainer>
      <FinancialSection title="Income">
        {incomeData.map((item) => (
          <LineItem
            key={item.label}
            label={item.label}
            value={`$${addCommasToNumber(item.value)}`}
          />
        ))}
        <TotalLine
          label="Total Income"
          value={`$${addCommasToNumber(totalIncome)}`}
        />
      </FinancialSection>

      <FinancialSection title="Cost of Goods Sold">
        {costOfGoodsSoldData.map((item) => (
          <LineItem
            key={item.label}
            label={item.label}
            value={`$${addCommasToNumber(item.value)}`}
          />
        ))}
        <TotalLine
          label="Total Cost of Goods Sold"
          value={`$${addCommasToNumber(totalCostOfGoodsSold)}`}
        />
      </FinancialSection>

      <ProfitSection
        title="Gross Profit"
        value={`$${addCommasToNumber(grossProfit)}`}
        percentage={grossProfitPercentage}
      />

      <br />

      <FinancialSection title="Operating Expenses">
        {operatingExpensesData.map((item) => (
          <LineItem
            key={item.label}
            label={item.label}
            value={`$${addCommasToNumber(item.value)}`}
          />
        ))}
        <TotalLine
          label="Total Operating Expenses"
          value={`$${addCommasToNumber(totalOperatingExpenses)}`}
        />
      </FinancialSection>

      <ProfitSection
        title="Net Profit"
        value={`$${addCommasToNumber(netProfit)}`}
        percentage={netProfitPercentage}
        isNegative={netProfit < 0}
      />
    </TabularContainer>
  );
};

export default FinancialStatement;

const FinancialSection = ({ title, children }) => (
  <>
    <SectionHeader>{title}</SectionHeader>
    {children}
  </>
);

const LineItem = ({ label, value }) => (
  <LineItemContainer>
    <span>{label}</span>
    <span>{value}</span>
  </LineItemContainer>
);

const TotalLine = ({ label, value }) => (
  <TotalLineContainer>
    <span>{label}</span>
    <span>{value}</span>
  </TotalLineContainer>
);

const ProfitSection = ({ title, value, percentage, isNegative = false }) => (
  <SectionHeader>
    <FlexItem justify="space-between">
      <span>{title}</span>
      <div>
        <span className={isNegative ? 'negative' : ''}>{value}</span>
      </div>
    </FlexItem>
    <FlexItem justify="space-between">
      <span style={{ fontWeight: 'normal' }}>
        As a percentage of Total Income
      </span>
      <div style={{ fontWeight: 'normal' }}>{percentage}</div>
    </FlexItem>
  </SectionHeader>
);
