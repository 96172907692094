import React, { memo, useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import AiSearchBar from './AiSearchBar.js';
import { useAiSearch } from '../../common/hooks/useAiSearch.js';
import FilterChips from './FilterChips.js';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { Container } from './styled.js';
import { useStore } from '@tanstack/react-store';
import { store, updateStore } from '../../../data/store.js';
import { colors } from '../../../themes/theme.js';

const AnimatedText = ({ text }) => {
  const hasCritiqueAnimated = useStore(
    store,
    (state) => state.hasCritiqueAnimated
  );
  const [displayText, setDisplayText] = useState('');

  useEffect(() => {
    if (!hasCritiqueAnimated) {
      const animateText = async () => {
        let currentText = '';
        for (let i = 0; i < text.length; i++) {
          currentText += text[i];
          setDisplayText(currentText);
          await new Promise((resolve) => setTimeout(resolve, 0));
        }
        updateStore({ hasCritiqueAnimated: true });
      };
      animateText();
    } else {
      setDisplayText(text);
    }
  }, [text, hasCritiqueAnimated]);

  return <Box dangerouslySetInnerHTML={{ __html: displayText }} />;
};

function EnhancedAiSearch({ fetchTransactions, onFilterChange }) {
  const { response, handleSearch, aiCritiqueError } = useAiSearch({
    onFilterChange,
  });
  const query = useStore(store, (state) => state.query);
  const critique = useStore(store, (state) => state.critique);

  return (
    <Container>
      <AiSearchBar
        query={query}
        handleSearch={handleSearch}
        fetchTransactions={fetchTransactions}
      />
      {critique && (
        <Box
          sx={{
            mt: 4,
            fontSize: '18px',
            backgroundColor: colors.aiCritiqueBg,
            padding: '20px 30px',
            borderRadius: '8px',
            lineHeight: '1.6',
          }}
        >
          <AnimatedText text={critique} />
        </Box>
      )}
      {aiCritiqueError ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          gap={2}
        >
          <Box
            display="flex"
            alignItems="center"
            style={{
              backgroundColor: colors.warningBg,
              padding: '10px 20px',
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              justifyContent: 'center',
              marginTop: '16px',
            }}
          >
            <AutoFixHighIcon style={{ color: colors.darkGray3 }} />
            <Typography variant="h6" style={{ color: colors.darkGray3 }}>
              {aiCritiqueError}... Try asking a question that is more specific
              to your financial data!
            </Typography>
          </Box>
        </Box>
      ) : response.aiInterpretation ? (
        <Box sx={{ mt: 4 }}>
          <FilterChips
            filters={response.aiInterpretation.filters}
            sort={response.aiInterpretation.sort}
            limit={response.aiInterpretation.limit}
          />
        </Box>
      ) : null}
    </Container>
  );
}

export default memo(EnhancedAiSearch);
