import { useCallback } from 'react';
import axiosInstance from '../../../api/axiosWrapper';
import { useStore } from '@tanstack/react-store';
import { store, updateStore } from '../../../data/store';
import { randomId } from '@mui/x-data-grid-generator';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const useAiSearch = ({ onFilterChange }) => {
  const isAiSearchLoading = useStore(store, (state) => state.isAiSearchLoading);
  const enableAiSearch = useStore(store, (state) => state.enableAiSearch);
  const aiInterpretation = useStore(store, (state) => state.aiInterpretation);
  const query = useStore(store, (state) => state.query);
  const transactions = useStore(store, (state) => state.transactions);
  const aiCritiqueError = useStore(store, (state) => state.aiCritiqueError);

  const calculateRowsFromTransactions = (transactions) => {
    return transactions.map((transaction) => ({
      id: transaction.transaction_id ?? randomId(),
      date: dayjs
        .utc(transaction.authorized_date)
        .local()
        .format('YYYY-MM-DD HH:mm:ss'),
      description: transaction.name,
      account: transaction.account?.name ?? 'Other',
      category: transaction?.category?.[0],
      amount: parseFloat(transaction.amount),
      receiptUrl: transaction.receiptUrl,
    }));
  };

  const handleSearch = useCallback(
    async (query) => {
      if (!enableAiSearch) {
        updateStore({ aiCritiqueError: 'AI Search is not enabled.' });
        return;
      }
      updateStore({
        aiCritiqueError: '',
        isAiSearchLoading: true,
        critique: null,
        hasCritiqueAnimated: false,
        aiInterpretation: null,
        transactionType: 'All',
      });
      if (onFilterChange) {
        onFilterChange('All');
      }
      let response = null;
      try {
        response = await axiosInstance.post('/ai-search', { query });

        updateStore({
          transactions: response.data.transactions || [],
          aiInterpretation: response.data.aiInterpretation || [],
          query: response.data.query || [],
          rows: calculateRowsFromTransactions(response.data.transactions || []),
          critique: response.data.critique || null,
        });
      } catch (error) {
        console.log(
          'error',
          error,
          error.response,
          error.response.status >= 400
        );
        if (error.response && error.response.status >= 400) {
          console.log('setting error message 1');
          updateStore({
            aiCritiqueError:
              'Your query cannot be processed as it does not contain specific criteria for searching financial transactions.',
          });
        } else {
          console.log('setting error message 2');
          updateStore({
            aiCritiqueError: 'An error occurred while fetching data.',
          });
        }
      } finally {
        updateStore({ isAiSearchLoading: false });
      }
    },
    [enableAiSearch]
  );

  return {
    response: {
      transactions: transactions,
      aiInterpretation: aiInterpretation,
      query: query,
    },
    handleSearch,
    isAiSearchLoading,
    aiCritiqueError,
  };
};

export { useAiSearch };
