import React, { useState } from 'react';
import { DashboardProvider } from '../../../components/common/contexts/DashboardContext';
import MetricsDisplay from '../../../components/features/Metrics Display';
import useTransactions from '../../../components/common/hooks/useTransactions';
import AlertPopup from '../../../components/common/AlertPopup';
import { Box } from '@mui/material';
import { colors } from '../../../themes/theme';
import { Outlet } from 'react-router-dom';
import { NavigationTabs } from '../../../components/features/NavigationTabs';
import { useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { store } from '../../../data/store';
import { useStore } from '@tanstack/react-store';

const Home = () => {
  const { fetchTransactions } = useTransactions();

  const [openAlert, setOpenAlert] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');
  const [timePeriod, setTimePeriod] = useState('all');
  const [filterState, setFilterState] = useState('All');

  const {
    transactions,
    accounts,
    isLoadingTransactions,
    minDateRange,
    maxDateRange,
    categories,
  } = useStore(store, (state) => ({
    transactions: state.transactions,
    accounts: state.accounts,
    isLoadingTransactions: state.isLoadingTransactions,
    minDateRange: state.minDateRange,
    maxDateRange: state.maxDateRange,
    categories: state.categories,
  }));

  const location = useLocation();

  const handleTimePeriodChange = (newTimePeriod) =>
    setTimePeriod(newTimePeriod);

  const handleFilterChange = (newFilterState) => setFilterState(newFilterState);

  if (isLoadingTransactions) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <DashboardProvider
      minDateRange={minDateRange}
      maxDateRange={maxDateRange}
      fetchTransactions={fetchTransactions}
    >
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: colors.gray,
          height: 'fit-content',
          padding: '10px 0px',
          gap: '50px',
        }}
      >
        <NavigationTabs pathname={location.pathname} />
        {location.pathname !== '/dashboard/reports' && (
          <MetricsDisplay
            transactions={transactions}
            onTimePeriodChange={handleTimePeriodChange}
            filterState={filterState}
          />
        )}
        <Outlet
          context={{
            transactions,
            categories,
            timePeriod,
            accounts,
            loadingData: isLoadingTransactions,
            setOpenAlert,
            setAlertSeverity,
            setAlertMessage,
            fetchTransactions,
            handleFilterChange,
          }}
        />
        {openAlert && (
          <AlertPopup
            open={openAlert}
            setOpen={setOpenAlert}
            alertMessage={alertMessage}
            alertSeverity={alertSeverity}
          />
        )}
      </Box>
    </DashboardProvider>
  );
};

export default Home;
