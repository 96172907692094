import { useState, useEffect } from 'react';

import { Link, useLocation } from 'react-router-dom';
import { HTRTabs, HTRTab } from '../../styles/style';
import HomeIcon from '@mui/icons-material/Home';
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { DASHBOARD_ROUTES } from '../../../data/constants';
import { store } from '../../../data/store';
import { useStore } from '@tanstack/react-store';

export function NavigationTabs() {
  const location = useLocation();
  const isDatagridPath = location.pathname === '/dashboard/datagrid';
  const { rowEditCount } = useStore(store, (state) => ({
    rowEditCount: state.rowEditCount,
  }));

  const initialTabValue = DASHBOARD_ROUTES.includes(location.pathname)
    ? location.pathname
    : DASHBOARD_ROUTES[0];

  const [value, setValue] = useState(initialTabValue);

  const handleChange = (event, newValue) => {
    if (!rowEditCount > 0) {
      setValue(newValue);
    }
  };

  useEffect(() => {
    setValue(initialTabValue);
  }, [initialTabValue]);

  return (
    <HTRTabs value={value} onChange={handleChange} centered>
      <HTRTab
        icon={<HomeIcon />}
        label="Home"
        value={DASHBOARD_ROUTES[0]}
        component={Link}
        to={DASHBOARD_ROUTES[0]}
        disabled={isDatagridPath && rowEditCount > 0}
      />
      <HTRTab
        icon={<ImportExportOutlinedIcon />}
        label="Transactions"
        value={DASHBOARD_ROUTES[1]}
        component={Link}
        to={DASHBOARD_ROUTES[1]}
        disabled={isDatagridPath && rowEditCount > 0}
      />
      <HTRTab
        icon={<AssignmentOutlinedIcon />}
        label="Reports"
        value={DASHBOARD_ROUTES[2]}
        component={Link}
        to={DASHBOARD_ROUTES[2]}
        disabled={isDatagridPath && rowEditCount > 0}
      />
    </HTRTabs>
  );
}
