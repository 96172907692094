import React, { useState, useEffect } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { colors, pieChart } from '../../../../themes/theme';
import negativeCategories from './ExpenseConstants';

// Get the CanvasJSChart component and CanvasJS object
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var CanvasJS = CanvasJSReact.CanvasJS;

dayjs.extend(isBetween);

const { pieChartColors } = pieChart;

// Create a map of category values to their labels and abbreviations
const categoryLabelMap = negativeCategories.reduce((acc, category) => {
  acc[category.value] = {
    label: category.label,
    abbrv: category.abbrv || category.label,
  };
  return acc;
}, {});

// Format number with custom rounding down
const formatNumber = (num) => {
  if (num >= 1000000) {
    const millions = Math.floor((num / 1000000) * 10) / 10;
    return `$${millions.toFixed(1)}m`;
  } else if (num >= 1000) {
    const thousands = Math.floor((num / 1000) * 10) / 10;
    return `$${thousands.toFixed(1)}k`;
  } else if (num >= 100) {
    return `$${Math.floor(num).toFixed(0)}`;
  } else {
    return `$${num.toFixed(2)}`;
  }
};

// Custom hook to get the window size
function useWindowSize() {
  const isClient = typeof window === 'object';

  function getSize() {
    return [
      isClient ? window.innerWidth : undefined,
      isClient ? window.innerHeight : undefined,
    ];
  }

  const [windowSize, setWindowSize] = useState(getSize());

  useEffect(() => {
    if (!isClient) return;
    function handleResize() {
      setWindowSize(getSize());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isClient]);

  return windowSize;
}

const PieChart = ({
  transactions,
  timePeriod,
  selectedWeeks,
  selectedMonths,
  selectedQuarters,
  selectedYears,
  startDate,
  endDate,
}) => {
  const [data, setData] = useState([]);
  const [width] = useWindowSize();

  useEffect(() => {
    // Filter transactions based on the selected time period
    const filteredTransactions = transactions.filter((transaction) => {
      const transactionDate = dayjs(
        transaction.authorized_date || transaction.date
      ).local();
      const transactionMonthStr = transactionDate.format('MMMM YYYY');
      const transactionQuarterStr = `Q${
        Math.floor(transactionDate.month() / 3) + 1
      } ${transactionDate.year()}`;
      const transactionYearStr = transactionDate.format('YYYY');

      switch (timePeriod) {
        case 'week':
          return selectedWeeks.some((week) => {
            const [start, end] = week.split(' - ');
            const year = end.split(', ')[1];
            const startDate = dayjs(`${start}, ${year}`).startOf('day');
            const endDate = dayjs(end).endOf('day');
            return transactionDate.isBetween(startDate, endDate, null, '[]');
          });
        case 'month':
          return selectedMonths.includes(transactionMonthStr);
        case 'quarter':
          return selectedQuarters.includes(transactionQuarterStr);
        case 'year':
          return selectedYears.includes(transactionYearStr);
        default:
          if (startDate && endDate) {
            const start = dayjs(startDate).startOf('day');
            const end = dayjs(endDate).endOf('day');
            return transactionDate.isBetween(start, end, null, '[]');
          }
          return true;
      }
    });

    // Calculate total amounts for each expense category
    const categoryTotals = filteredTransactions.reduce((acc, transaction) => {
      const rawCategory = transaction.category?.[0] || 'UNCATEGORIZED_EXPENSE';
      const categoryKey = rawCategory.toUpperCase().replace(/ /g, '_');
      const categoryInfo = categoryLabelMap[categoryKey] || {
        label: 'Uncategorized Expense',
        abbrv: 'Uncategorized Exp',
      };
      const categoryAbbrv = categoryInfo.abbrv;

      const amount = parseFloat(transaction.amount);
      // Only include negative amounts (expenses)
      if (amount < 0) {
        acc[categoryAbbrv] = (acc[categoryAbbrv] || 0) + Math.abs(amount);
      }
      return acc;
    }, {});

    // Prepare data for the chart
    const newData = Object.entries(categoryTotals)
      .map(([name, y]) => ({
        y,
        name,
        legendText: name,
        showInLegend: true,
      }))
      .sort((a, b) => b.y - a.y);

    setData(newData);
  }, [
    transactions,
    timePeriod,
    selectedWeeks,
    selectedMonths,
    selectedQuarters,
    selectedYears,
    startDate,
    endDate,
  ]);

  // Function to get responsive font sizes based on screen width
  const getResponsiveFontSize = (desktopSize, mobileSize) => {
    return width < 768 ? mobileSize : desktopSize;
  };

  // Add the custom color set to CanvasJS
  CanvasJS.addColorSet('customColorSet', pieChartColors);

  const options = {
    height: 275,
    animationEnabled: true,
    exportEnabled: true,
    colorSet: 'customColorSet', // Use the custom color set
    subtitles: [
      {
        fontFamily: 'arial',
        text: formatNumber(data.reduce((sum, item) => sum + item.y, 0)),
        verticalAlign: 'center',
        fontSize: getResponsiveFontSize(24, 16),
        dockInsidePlotArea: true,
      },
    ],
    legend: {
      fontFamily: 'arial',
      horizontalAlign: 'center',
      verticalAlign: 'bottom',
      fontSize: getResponsiveFontSize(14, 12),
      itemWidth: 200,
    },
    data: [
      {
        type: 'doughnut',
        indexLabel: '{name} - #percent%',
        indexLabelFontSize: getResponsiveFontSize(14, 12),
        indexLabelPlacement: 'outside',
        yValueFormatString: '$#,##0.00',
        dataPoints: data,
        explodeOnClick: false,
      },
    ],
    toolTip: {
      fontFamily: 'arial',
      content: '{name} - {y}',
      yValueFormatString: '$#,##0.00',
    },
  };

  // If there's no data to display, show a message
  if (data.length === 0) {
    return (
      <div
        style={{
          height: 200,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <span style={{ fontSize: '16px', color: colors.lightGray3 }}>
          No data available for this period
        </span>
      </div>
    );
  }

  return (
    <div style={{ position: 'relative', width: '100%', height: '275px' }}>
      <CanvasJSChart
        options={options}
        containerProps={{ width: '100%', height: '100%' }}
      />
    </div>
  );
};

export default PieChart;
