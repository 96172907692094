import React from 'react';
import FullFeaturedCrudGrid from '../../../components/features/Data Grid';
import { useOutletContext } from 'react-router-dom';
import { useDashboardContext } from '../../../components/common/contexts/DashboardContext';
import { useStore } from '@tanstack/react-store';
import { store } from '../../../data/store';

const DataGridPage = () => {
  const {
    setOpenAlert,
    setAlertSeverity,
    setAlertMessage,
    handleFilterChange,
    categories,
    setIsEditing,
  } = useOutletContext();

  const { rows, accounts, isLoadingTransactions } = useStore(
    store,
    (state) => ({
      rows: state.rows,
      accounts: state.accounts,
      isLoadingTransactions: state.isLoadingTransactions,
    })
  );

  const {
    activeTimeTab,
    selectedWeeks,
    selectedMonths,
    selectedQuarters,
    selectedYears,
    startDate,
    endDate,
    fetchTransactions,
  } = useDashboardContext();

  return (
    <FullFeaturedCrudGrid
      rows={isLoadingTransactions ? [] : rows}
      accounts={accounts}
      timePeriod={activeTimeTab}
      setOpenAlert={setOpenAlert}
      setAlertSeverity={setAlertSeverity}
      setAlertMessage={setAlertMessage}
      fetchTransactions={fetchTransactions}
      onFilterChange={handleFilterChange}
      categories={categories}
      setIsEditing={setIsEditing}
      selectedWeeks={selectedWeeks}
      selectedMonths={selectedMonths}
      selectedQuarters={selectedQuarters}
      selectedYears={selectedYears}
      startDate={startDate}
      endDate={endDate}
    />
  );
};

export default DataGridPage;
