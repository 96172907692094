import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

const convertToLocalTime = (date) => {
  return dayjs.utc(date).local();
};

const DATE_FORMATS = {
  WEEK: 'MMM D',
  WEEK_YEAR: 'MMM D, YYYY',
  MONTH: 'MMMM YYYY',
  QUARTER: '[Q]Q YYYY',
  YEAR: 'YYYY',
};

const generateOptions = (transactions, startDate, endDate, unit, format) => {
  const options = new Set();
  transactions.forEach((transaction) => {
    const transactionDate = convertToLocalTime(transaction.authorized_date);
    if (
      transactionDate.isBetween(dayjs(startDate), dayjs(endDate), null, '[]')
    ) {
      const formattedDate =
        unit === 'week'
          ? `${transactionDate.startOf(unit).format(DATE_FORMATS.WEEK)} - ${transactionDate.endOf(unit).format(DATE_FORMATS.WEEK_YEAR)}`
          : transactionDate.startOf(unit).format(format);
      options.add(formattedDate);
    }
  });
  return Array.from(options).sort((a, b) => new Date(a) - new Date(b));
};

export const generateWeekOptions = (transactions, startDate, endDate) =>
  generateOptions(
    transactions,
    startDate,
    endDate,
    'week',
    DATE_FORMATS.WEEK_YEAR
  );

export const generateMonthOptions = (transactions, startDate, endDate) =>
  generateOptions(
    transactions,
    startDate,
    endDate,
    'month',
    DATE_FORMATS.MONTH
  );

export const generateQuarterOptions = (transactions, startDate, endDate) =>
  generateOptions(
    transactions,
    startDate,
    endDate,
    'quarter',
    DATE_FORMATS.QUARTER
  );

export const generateYearOptions = (transactions, startDate, endDate) =>
  generateOptions(transactions, startDate, endDate, 'year', DATE_FORMATS.YEAR);

const handleSelectionChange = (
  event,
  options,
  setSelected,
  clearOtherSelections
) => {
  const value = event.target.value;
  if (value.includes('select-all')) {
    setSelected(options);
  } else if (value.includes('deselect-all')) {
    setSelected([]);
  } else {
    setSelected(value);
  }
  clearOtherSelections();
};

export const handleWeekSelectionChange = (
  event,
  weekOptions,
  setSelectedWeeks,
  setSelectedMonths,
  setSelectedQuarters,
  setSelectedYears
) =>
  handleSelectionChange(event, weekOptions, setSelectedWeeks, () => {
    setSelectedMonths([]);
    setSelectedQuarters([]);
    setSelectedYears([]);
  });

export const handleMonthSelectionChange = (
  event,
  monthOptions,
  setSelectedWeeks,
  setSelectedMonths,
  setSelectedQuarters,
  setSelectedYears
) =>
  handleSelectionChange(event, monthOptions, setSelectedMonths, () => {
    setSelectedWeeks([]);
    setSelectedQuarters([]);
    setSelectedYears([]);
  });

export const handleQuarterSelectionChange = (
  event,
  quarterOptions,
  setSelectedWeeks,
  setSelectedMonths,
  setSelectedQuarters,
  setSelectedYears
) =>
  handleSelectionChange(event, quarterOptions, setSelectedQuarters, () => {
    setSelectedWeeks([]);
    setSelectedMonths([]);
    setSelectedYears([]);
  });

export const handleYearSelectionChange = (
  event,
  yearOptions,
  setSelectedWeeks,
  setSelectedMonths,
  setSelectedQuarters,
  setSelectedYears
) =>
  handleSelectionChange(event, yearOptions, setSelectedYears, () => {
    setSelectedWeeks([]);
    setSelectedMonths([]);
    setSelectedQuarters([]);
  });
