import React, { useState, useEffect } from 'react';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import AlertPopup from '../../common/AlertPopup';

import ResetPasswordRedirect from './ResetPasswordRedirect';
import ResetPasswordRequest from './ResetPasswordRequest';
import firebaseAuth from '../../services/api/firebase-service';
import { ALERT_POPUP_SEVERITY } from '../../../data/constants';

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="#">
        ONEBIT
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const ResetPassword = () => {
  const [resetCode, setResetCode] = useState(null);
  const [emailId, setEmailId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [openAlert, setOpenAlert] = useState(null);

  const retrievePasswordResetCredentials = () => {
    const urlQueryParams = new URLSearchParams(window.location.search);
    const apiKey = urlQueryParams.get('apiKey') ?? '';
    const resetCode = urlQueryParams.get('oobCode') ?? '';
    return { apiKey, resetCode };
  };

  const verifyPasswordResetCode = async () => {
    const { apiKey, resetCode } = retrievePasswordResetCredentials();

    if (!resetCode || resetCode === '') {
      setResetCode(resetCode);
      return;
    }

    if (!apiKey || apiKey === '') {
      setResetCode('INVALID');
      return;
    }

    firebaseAuth
      .firebaseVerifyPasswordResetCode(apiKey, resetCode)
      .then((userEmail) => {
        setEmailId(userEmail);
        setResetCode(resetCode);
      })
      .catch(() => {
        setResetCode('INVALID');
      });
    return;
  };

  useEffect(() => {
    verifyPasswordResetCode();
  });

  useEffect(() => {
    if (errorMessage) {
      setOpenAlert(true);
    }
  }, [errorMessage]);

  return (
    <Container component="main" maxWidth="xs">
      {resetCode === null ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : resetCode === '' ? (
        <ResetPasswordRequest setErrorMessage={setErrorMessage} />
      ) : (
        <ResetPasswordRedirect
          resetCode={resetCode}
          setResetCode={setResetCode}
          emailId={emailId}
        />
      )}
      <Copyright sx={{ mt: 3 }} />
      {errorMessage && (
        <AlertPopup
          open={openAlert}
          setOpen={setOpenAlert}
          alertMessage={errorMessage}
          alertSeverity={ALERT_POPUP_SEVERITY.error}
        />
      )}
    </Container>
  );
};

export default ResetPassword;
