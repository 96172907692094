import React, { useState, useContext, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { SButton } from './style';
import Alert from '@mui/material/Alert';
import { AuthContext } from '../../common/contexts/authContext';

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="#">
        ONEBIT
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const SignIn = ({
  user,
  setUser,
  handleSignUpClick,
  handleResetPasswordClick,
  errorMessage,
  setErrorMessage,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({ email: '', password: '' });
  const sButtonRef = useRef(null);
  const { login } = useContext(AuthContext);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (sButtonRef.current) {
        sButtonRef.current.click();
      }
    }
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let formErrors = {};

    if (!user.emailId) {
      formErrors.email = 'Email is required';
    } else if (!validateEmail(user.emailId)) {
      formErrors.email = 'Invalid email address';
    }

    if (!user.password) {
      formErrors.password = 'Password is required';
    } else if (user.password.length < 8) {
      formErrors.password = 'Password must be at least 8 characters';
    }

    if (Object.keys(formErrors).length === 0) {
      try {
        await login(user);
      } catch (error) {
        setErrorMessage(error.message || 'Failed to log in. Please try again.');
      }
    } else {
      setErrors(formErrors);
    }
  };

  const handleError = () => {
    if (errorMessage) setErrorMessage('');
    setErrors({ email: '', password: '' });
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            style={{ fontWeight: 'bold' }}
          >
            Sign in to Your Account{' '}
          </Typography>
          <Typography variant="body1">
            Don't have an account yet?{' '}
            <Link href="#" component="span">
              <Typography
                component="span"
                variant="body1"
                style={{ fontWeight: 'bold', cursor: 'pointer' }}
                onClick={handleSignUpClick}
              >
                Sign up
              </Typography>
            </Link>
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleFormSubmit}
            onKeyDown={handleKeyDown}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography component="span" variant="h6">
                  Email
                </Typography>
                <TextField
                  defaultValue={user.emailId}
                  sx={{ mt: 1.2 }}
                  required
                  fullWidth
                  id="email"
                  label="Enter your email"
                  name="email"
                  autoComplete="email"
                  error={Boolean(errors.email)}
                  helperText={errors.email}
                  onChange={(event) => {
                    setUser({ ...user, emailId: event.target.value });
                    handleError();
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography component="span" variant="h6">
                  Password
                </Typography>
                <TextField
                  sx={{ mt: 1.2 }}
                  required
                  fullWidth
                  id="password"
                  label="Enter your password"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  autoComplete="new-password"
                  error={Boolean(errors.password)}
                  helperText={errors.password}
                  onChange={(event) => {
                    setUser({ ...user, password: event.target.value });
                    handleError();
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Link
                  variant="body1"
                  component="button"
                  onClick={handleResetPasswordClick}
                >
                  Forgot your password?
                </Link>
              </Grid>
            </Grid>
            {errorMessage && (
              <Alert severity="error" sx={{ mt: 3 }}>
                {errorMessage}
              </Alert>
            )}
            <SButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3 }}
              ref={sButtonRef}
            >
              Sign in
            </SButton>
          </Box>
        </Box>
        <Copyright sx={{ mt: 3 }} />
      </Container>
    </>
  );
};
export default SignIn;
