import React, { useState, useCallback, useEffect } from 'react';
import { Box, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { colors } from '../../../themes/theme.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const shortcutsItems = [
  { label: 'Week', value: '7days' },
  { label: 'Month', value: '30days' },
  { label: 'Quarter', value: '90days' },
  { label: 'Year', value: '365days' },
  { label: 'All', value: 'all' },
];

function TimeTabsModal({
  handleReset,
  closeModal,
  onMobileShortcutSelect,
  clearDesktopFilters,
  modalOpen,
}) {
  const [selectedShortcut, setSelectedShortcut] = useState(() => {
    return localStorage.getItem('lastMobileShortcut') || 'all';
  }); // Tracks selected shortcut

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleShortcutClick = useCallback(
    (shortcut) => {
      setSelectedShortcut(shortcut);
      localStorage.setItem('lastMobileShortcut', shortcut);
      if (shortcut === 'all') {
        handleReset(); // Reset all filters for 'All'
      } else {
        onMobileShortcutSelect(shortcut);
      }
    },
    [handleReset, onMobileShortcutSelect]
  );

  // When switching between mobile and desktop, clear filters only if switching
  useEffect(() => {
    if (isMobile && !modalOpen) {
      clearDesktopFilters(); // Only clear desktop filters on mobile view switch
    }
  }, [isMobile, modalOpen, clearDesktopFilters]);

  return (
    <Box sx={containerStyles}>
      {/* Close Icon */}
      <IconButton onClick={closeModal} sx={closeIconStyles}>
        <CloseIcon />
      </IconButton>

      <Box sx={{ width: '100%', mt: 4 }}>
        {/* Title */}
        <Box sx={titleStyles}>Select Time Range</Box>

        {/* Shortcut Buttons */}
        <Box sx={shortcutButtonsContainerStyles}>
          {shortcutsItems.map((shortcut) => (
            <Button
              key={shortcut.value}
              variant="contained"
              size="medium"
              onClick={() => handleShortcutClick(shortcut.value)}
              sx={getShortcutButtonStyles(selectedShortcut === shortcut.value)}
            >
              {shortcut.label}
            </Button>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

// Styles
const containerStyles = {
  p: 3,
  pt: 2,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  position: 'relative',
  bgcolor: colors.white,
  borderRadius: 2,
  maxWidth: '600px',
  mx: 'auto',
};

const closeIconStyles = {
  position: 'absolute',
  top: 8,
  right: 8,
  color: colors.lightGray4,
  '&:hover': {
    color: colors.darkGray2,
    bgcolor: colors.lightGray2,
  },
};

const titleStyles = {
  textAlign: 'center',
  fontSize: '1.1rem',
  fontWeight: 500,
  color: colors.darkGray2,
  mb: 3,
};

const shortcutButtonsContainerStyles = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: 2,
  width: '100%',
  px: 2,
};

const getShortcutButtonStyles = (isSelected) => ({
  borderRadius: 6,
  textTransform: 'none',
  backgroundColor: isSelected ? colors.secondary : colors.primary,
  color: colors.white,
  px: 3,
  py: 1,
  minWidth: '100px',
  '&:hover': {
    backgroundColor: isSelected ? colors.secondary : colors.primary,
    opacity: 0.9,
  },
});

export default React.memo(TimeTabsModal);
