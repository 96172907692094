import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Snackbar,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItemIcon,
  Card,
  CardContent,
  TextField,
  CardActions,
  CircularProgress,
  Skeleton,
} from '@mui/material';
import axiosInstance from '../../../../api/axiosWrapper';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HomeIcon from '@mui/icons-material/Home';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SavingsIcon from '@mui/icons-material/Savings';
import DeleteIcon from '@mui/icons-material/Delete';
import { Alert } from '@mui/material';
import { colors } from '../../../../themes/theme';
import LinkBankAccountModal from '../../../../components/common/LinkBankAccountModal';
import { AuthContext } from '../../../../components/common/contexts/authContext';
import { TRANSACTIONS_QUERY_KEY } from '../../../../data/constants';
import { useQueryClient } from '@tanstack/react-query';

const BankAccountInfo = () => {
  const { currentUser, isLoggedIn } = useContext(AuthContext);

  const [bankAccounts, setBankAccounts] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [verificationText, setVerificationText] = useState('');
  const [dialogType, setDialogType] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [currentLinkToken, setCurrentLinkToken] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [reauthItems, setReauthItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentReauthIndex, setCurrentReauthIndex] = useState(0);

  const queryClient = useQueryClient();

  useEffect(() => {
    const fetchBankAccounts = async () => {
      if (currentUser && isLoggedIn) {
        setLoading(true);
        try {
          const response = await axiosInstance.get('/getPlaidItemsForUser');
          const { items, reauthItems } = response.data;
          setBankAccounts(items);
          setReauthItems(reauthItems);

          if (reauthItems && reauthItems.length > 0) {
            setCurrentLinkToken(reauthItems[0].linkToken);
            setOpenAlert(true);
            setModalOpen(true);
          }

          setLoading(false);
        } catch (error) {
          console.error('Error fetching bank accounts:', error);
          setLoading(false);
        }
      }
    };

    fetchBankAccounts();
  }, [currentUser, isLoggedIn]);

  const handleModalClose = () => {
    setModalOpen(false);

    const nextIndex = currentReauthIndex + 1;
    if (nextIndex < reauthItems.length) {
      setCurrentReauthIndex(nextIndex);
      setCurrentLinkToken(reauthItems[nextIndex].linkToken);
      setModalOpen(true);
    }
  };

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const handleRemoveAccount = async (itemId, accountId) => {
    try {
      await axiosInstance.post('/deactivateAccount', { itemId, accountId });
      const updatedAccounts = bankAccounts.map((bank) => {
        if (bank.id === itemId) {
          return {
            ...bank,
            accounts: bank.accounts.map((account) =>
              account.id === accountId ? { ...account, is_active: 0 } : account
            ),
          };
        }
        return bank;
      });
      setBankAccounts(updatedAccounts);
      setSnackbarMessage('Bank account removed successfully!');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error removing bank account:', error);
      setSnackbarMessage('Failed to remove bank account. Please try again.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } finally {
      queryClient.removeQueries({
        queryKey: TRANSACTIONS_QUERY_KEY,
        exact: true,
      });
    }
  };

  const handleUnlinkItem = async (itemId) => {
    try {
      await axiosInstance.post('/deactivatePlaidItem', { itemId });
      const updatedItems = bankAccounts.filter((item) => item.id !== itemId);
      setBankAccounts(updatedItems);
      setSnackbarMessage('Bank item unlinked successfully!');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error unlinking bank item:', error);
      setSnackbarMessage('Failed to unlink bank item. Please try again.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } finally {
      queryClient.removeQueries({
        queryKey: TRANSACTIONS_QUERY_KEY,
        exact: true,
        refetchType: 'none',
      });
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleLinkNewBank = () => {
    setModalOpen(true);
  };

  const getAccountIcon = (subtype) => {
    switch (subtype) {
      case 'checking':
        return <HomeIcon sx={{ color: colors.black }} />;
      case 'savings':
        return <SavingsIcon sx={{ color: colors.black }} />;
      case 'credit':
        return <CreditCardIcon sx={{ color: colors.black }} />;
      default:
        return <AccountBalanceIcon sx={{ color: colors.black }} />;
    }
  };

  const handleOpenDialog = (itemId, accountId, type) => {
    setSelectedAccountId(accountId);
    setSelectedItemId(itemId);
    setDialogType(type);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedAccountId(null);
    setSelectedItemId(null);
    setDialogType('');
    setVerificationText('');
  };

  const handleConfirmRemove = () => {
    if (dialogType === 'account') {
      handleRemoveAccount(selectedItemId, selectedAccountId);
    } else if (dialogType === 'item') {
      handleUnlinkItem(selectedItemId);
    }
    setVerificationText('');
    handleCloseDialog();
  };

  return (
    <Box sx={{ padding: '20px', backgroundColor: colors.lightGray }}>
      <Typography variant="h5" sx={{ mb: 2, textAlign: 'left' }}>
        Linked Accounts
      </Typography>

      {reauthItems.length > 0 ? (
        <Snackbar
          open={openAlert}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={handleAlertClose}
            severity="warning"
            sx={{ width: '100%' }}
          >
            Some of your accounts require re-authentication.
          </Alert>
        </Snackbar>
      ) : null}

      <Button
        variant="contained"
        onClick={handleLinkNewBank}
        disabled={loading}
        sx={{
          display: 'block',
          borderRadius: '20px',
          ml: 'auto',
          mb: 2,
          backgroundColor: colors.primary,
          color: colors.white,
          textTransform: 'none',
          '&:hover': {
            backgroundColor: colors.primaryHover,
          },
        }}
      >
        {bankAccounts.length > 0
          ? 'Add Another Bank Account'
          : 'Connect a Bank Account'}
      </Button>
      <Divider sx={{ mb: 2 }} />
      {loading ? (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <CircularProgress />
          <Box mt={2}>
            {[1, 2, 3].map((i) => (
              <Skeleton
                key={i}
                variant="rectangular"
                height={200}
                sx={{ mb: 2, borderRadius: '10px' }}
              />
            ))}
          </Box>
        </Box>
      ) : (
        <Box>
          {bankAccounts.map((bank) => (
            <Card
              key={bank.id}
              sx={{
                mb: 3,
                maxWidth: '800px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    algnItems: 'center',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <AccountBalanceIcon sx={{ mr: 1, color: colors.black }} />
                    <Typography
                      sx={{ fontWeight: 900, marginLeft: '20px' }}
                      variant="h6"
                    >
                      {bank.institutionName}
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ my: 2 }} />
                <List>
                  {bank.accounts
                    .filter((account) => account.is_active !== 0)
                    .map((account) => (
                      <ListItem
                        key={account.id}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <ListItemIcon>
                          {getAccountIcon(account.subtype)}
                        </ListItemIcon>
                        <ListItemText
                          primary={account.name}
                          secondary={`${account.number} | ${
                            account.type.charAt(0).toUpperCase() +
                            account.type.slice(1)
                          } - ${
                            account.subtype.charAt(0).toUpperCase() +
                            account.subtype.slice(1)
                          }`}
                        />
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() =>
                            handleOpenDialog(bank.id, account.id, 'account')
                          }
                          sx={{
                            color: colors.black,
                            '&:hover': {
                              color: colors.error,
                            },
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItem>
                    ))}
                </List>
              </CardContent>
              <CardActions>
                <Button
                  variant="outlined"
                  onClick={() => handleOpenDialog(bank.id, null, 'item')}
                  sx={{
                    ml: 'auto',
                    borderRadius: '20px',
                    backgroundColor: colors.primary,
                    color: colors.white,
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: colors.error,
                      borderColor: colors.error,
                      color: colors.white,
                    },
                  }}
                >
                  Unlink
                </Button>
              </CardActions>
            </Card>
          ))}
        </Box>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Removal</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to remove this bank account? Type "delete" to
            confirm.
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            value={verificationText}
            onChange={(e) => setVerificationText(e.target.value)}
            placeholder="Type 'delete' to confirm"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="primary"
            sx={{ textTransform: 'none' }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmRemove}
            sx={{ color: colors.error, textTransform: 'none' }}
            disabled={verificationText.toLowerCase() !== 'delete'}
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>

      <LinkBankAccountModal
        open={modalOpen}
        handleClose={handleModalClose}
        loadingData={loadingData}
        setLoadingData={setLoadingData}
        itemId={reauthItems[currentReauthIndex]?.itemId}
        mode="update"
        linkToken={currentLinkToken}
      />
    </Box>
  );
};

export default BankAccountInfo;
