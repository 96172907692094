import React from 'react';
import {
  SFormControl,
  SInputLabel,
  SSelect,
  SMenuItem,
} from '../../styles/style';
import { useMediaQuery, useTheme } from '@mui/material';
import { useStore } from '@tanstack/react-store';
import { store, updateStore } from '../../../data/store';

const TransactionFilter = ({ onFilterChange }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const transactionType = useStore(store, (state) => state.transactionType);
  const handleTransactionTypeChange = (e) => {
    updateStore({ transactionType: e.target.value });
    if (onFilterChange) {
      onFilterChange(e.target.value);
    }
  };

  return (
    <SFormControl
      sx={{
        m: isDesktop ? 1 : 0,
        width: isDesktop ? '100px' : '100%',
        '& .MuiInputBase-root': {
          height: '40px',
        },
      }}
      size="small"
    >
      <SInputLabel id="toggle-select-small-label">Filters</SInputLabel>
      <SSelect
        labelId="toggle-select-small-label"
        id="toggle-select-small"
        value={transactionType}
        label="Filters"
        onChange={handleTransactionTypeChange}
        color="primary"
      >
        <SMenuItem value="All">All</SMenuItem>
        <SMenuItem value="income">Income</SMenuItem>
        <SMenuItem value="expenses">Expenses</SMenuItem>
      </SSelect>
    </SFormControl>
  );
};

export default TransactionFilter;
