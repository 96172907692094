import { initializeApp } from 'firebase/app';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  getIdToken,
  setPersistence,
  inMemoryPersistence,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from 'firebase/auth';
import axiosInstance from '../../../api/axiosWrapper';

const firebaseApp = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

const auth = getAuth(firebaseApp);
setPersistence(auth, inMemoryPersistence);

const getFirebaseAuth = () => auth;

// Create new account using email/password
const createFirebaseUser = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    return userCredential.user;
  } catch (error) {
    console.error(`Error creating user in Firebase Auth: ${error.message}`);
    throw error;
  }
};

const firebaseEmailPassLogin = async (loginReq) => {
  const { emailId, password } = loginReq;

  try {
    const { user } = await signInWithEmailAndPassword(auth, emailId, password);
    const idToken = await getIdToken(user);

    const response = await axiosInstance.post(
      '/firebaseSessionLogin',
      {},
      {
        headers: { Authorization: `Bearer ${idToken}` },
      }
    );
    return { ...response, user };
  } catch (error) {
    console.error(`Error during Firebase login: ${error.message}`);
    throw error;
  }
};

const firebaseSendPassResetEmail = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error) {
    console.error(`Error sending password reset email: ${error.message}`);
    throw error;
  }
};

const firebaseVerifyPasswordResetCode = async (apiKey, resetCode) => {
  try {
    const userEmail = await verifyPasswordResetCode(auth, resetCode);
    const backendVerificationResponse = await axiosInstance.post(
      '/auth/password-reset/verify',
      {
        apiKey,
        resetCode,
      }
    );

    if (backendVerificationResponse?.data?.email !== userEmail) {
      throw new Error('Unable to verify reset credentials.');
    }
    return userEmail;
  } catch (error) {
    throw error;
  }
};

const firebaseConfirmPasswordReset = async (code, newPassword) => {
  try {
    await confirmPasswordReset(auth, code, newPassword);
  } catch (error) {
    console.error(`Error confirming password reset: ${error.message}`);
    throw error;
  }
};

const getFirebaseIdToken = async () => {
  if (!auth.currentUser) {
    throw new Error('No Firebase user found');
  }
  try {
    return await getIdToken(auth.currentUser);
  } catch (error) {
    console.error(`Error getting Firebase ID token: ${error.message}`);
    throw error;
  }
};

const getFirebaseUID = async () => {
  if (!auth.currentUser) {
    throw new Error('No Firebase user found');
  }
  return auth.currentUser.uid;
};

const firebaseAuth = {
  getFirebaseAuth,
  createFirebaseUser,
  firebaseEmailPassLogin,
  firebaseSendPassResetEmail,
  firebaseVerifyPasswordResetCode,
  firebaseConfirmPasswordReset,
  getFirebaseIdToken,
  getFirebaseUID,
};

export default firebaseAuth;
