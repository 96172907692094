import axiosInstance from '../../../api/axiosWrapper';

// Register User
const registerUser = async (userToRegister, firebaseUID, firebaseToken) => {
  try {
    const response = await axiosInstance.post(
      '/registerUser',
      { ...userToRegister, firebaseUID },
      {
        headers: {
          Authorization: `Bearer ${firebaseToken}`,
        },
      }
    );
    return response.data.registeredUser;
  } catch (error) {
    console.error(`Error registering user in the backend: ${error.message}`);
    throw error;
  }
};

// Get User by Token
const getUserByToken = async (firebaseToken) => {
  try {
    const response = await axiosInstance.post(
      '/getUserByToken',
      {},
      {
        headers: {
          Authorization: `Bearer ${firebaseToken}`,
        },
      }
    );
    return response.data.user;
  } catch (error) {
    console.error(
      `Error fetching user by token from the backend: ${error.message}`
    );
    throw error;
  }
};

const userService = {
  registerUser,
  getUserByToken,
};

export default userService;
