import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { colors } from '../../../themes/theme';

export const LinkButton = styled(Button)({
  backgroundColor: colors.primary,
  textTransform: 'none',
  height: '35px',
  fontSize: '0.8rem',
  whiteSpace: 'nowrap',
  borderRadius: '50px',
  color: colors.white,
  padding: '8px 15px',
  '&:hover': {
    backgroundColor: colors.primaryHover,
  },
});
