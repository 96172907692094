import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import Papa from 'papaparse';
import axiosInstance from '../../../api/axiosWrapper.js';

const useReportData = ({ startDate, endDate }) => {
  const [reportsData, setReportsData] = useState({
    income: 0,
    costOfGoodsSold: 0,
    operatingExpenses: 0,
    netProfit: 0,
    incomeData: [],
    costOfGoodsSoldData: [],
    operatingExpensesData: [],
    totalIncome: 0,
    totalCostOfGoodsSold: 0,
    totalOperatingExpenses: 0,
    grossProfit: 0,
  });

  const [loading, setLoading] = useState(false);

  const fetchReportData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `/transactions/report?startDate=${dayjs(startDate).format('YYYY-MM-DD')}&endDate=${dayjs(endDate).format('YYYY-MM-DD')}`
      );

      const { summary, details } = response.data;

      setReportsData({
        income: summary.income,
        costOfGoodsSold: summary.costOfGoodsSold,
        operatingExpenses: summary.operatingExpenses,
        netProfit: summary.netProfit,
        incomeData: Object.entries(details.income)
          .map(([key, value]) =>
            key === 'total'
              ? null
              : {
                  label: key.replaceAll(/_/g, ' '),
                  value: `$${parseFloat(value).toFixed(2)}`,
                  amount: parseFloat(value),
                }
          )
          .filter((x) => x),
        costOfGoodsSoldData: Object.entries(details.costOfGoodsSold)
          .map(([key, value]) =>
            key === 'total'
              ? null
              : {
                  label: key.replaceAll(/_/g, ' '),
                  value: `$${parseFloat(value).toFixed(2)}`,
                  amount: parseFloat(value),
                }
          )
          .filter((x) => x),
        operatingExpensesData: Object.entries(details.operatingExpenses)
          .map(([key, value]) =>
            key === 'total'
              ? null
              : {
                  label: key.replaceAll(/_/g, ' '),
                  value: `$${parseFloat(value).toFixed(2)}`,
                  amount: parseFloat(value),
                }
          )
          .filter((x) => x),
        totalIncome: details.income.total.toFixed(2),
        totalCostOfGoodsSold: details.costOfGoodsSold.total,
        totalOperatingExpenses: details.operatingExpenses.total,
        grossProfit: details.grossProfit.amount,
      });
    } catch (error) {
      console.error('Error fetching report data:', error);
    } finally {
      setLoading(false); // Set loading to false when fetching ends
    }
  };

  useEffect(() => {
    if (
      startDate &&
      startDate !== 'Invalid Date' &&
      endDate &&
      endDate !== 'Invalid Date'
    ) {
      fetchReportData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const handleExport = () => {
    const csvData = [
      [
        'Accounts',
        `${dayjs(startDate).format('MM-DD-YYYY')} to ${dayjs(endDate).format('MM-DD-YYYY')}`,
      ],
      ['Income', 'Amount'],
      ...reportsData.incomeData.map((item) => [
        item.label.replace(/\b\w/g, (char) => char.toUpperCase()),
        item.value,
      ]),
      ['', ''],
      ['Total Income', `$${reportsData.totalIncome}`],
      ['', ''],
      ['Cost of Goods', 'Amount'],
      ...reportsData.costOfGoodsSoldData.map((item) => [
        item.label.replace(/\b\w/g, (char) => char.toUpperCase()),
        item.value,
      ]),
      ['Total Cost of Goods Sold', `$${reportsData.totalCostOfGoodsSold}`],
      ['', ''],
      ['Gross Profit', `$${reportsData.grossProfit}`],
      ['', ''],
      ['Operating Expenses', 'Amount'],
      ...reportsData.operatingExpensesData.map((item) => [
        item.label.replace(/\b\w/g, (char) => char.toUpperCase()),
        item.value,
      ]),
      ['', ''],
      ['Total Operating Expenses', `$${reportsData.totalOperatingExpenses}`],
      ['Net Profit', `$${reportsData.netProfit.toFixed(2)}`],
    ];

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute(
      'download',
      `Report : ${dayjs(startDate).format('MM-DD-YYYY')} to ${dayjs(endDate).format('MM-DD-YYYY')}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return {
    reportsData,
    handleExport,
    loading,
  };
};

export default useReportData;
