import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PlaidLink from './PlaidLink';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Link from '@mui/material/Link';
import { colors } from '../../../themes/theme';

const LinkAccountsPage = ({
  loadingData,
  setLoadingData,
  linkToken,
  onSuccess,
  onExit,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        padding: '20px',
        width: '100%',
        backgroundColor: colors.white,
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
          width: '100%',
          maxWidth: '600px',
          padding: '40px',
          backgroundColor: colors.white,
        }}
      >
        <AccountBalanceIcon
          sx={{ fontSize: 60, color: colors.primary, marginBottom: '20px' }}
        />
        <Typography
          variant="h4"
          sx={{
            fontWeight: 'bold',
            color: colors.darkGray3,
            marginBottom: '20px',
          }}
        >
          Link Your Accounts
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: colors.mediumGray, marginBottom: '20px' }}
        >
          Securely connect with your bank accounts using{' '}
          <Link
            href="https://plaid.com"
            target="_blank"
            rel="noopener"
            sx={{
              color: colors.primary,
              textDecoration: 'none',
              '&:hover': { textDecoration: 'underline' },
            }}
          >
            Plaid
          </Link>
          . <br />
          <Link
            href="https://onebitapp.com"
            target="_blank"
            rel="noopener"
            sx={{
              color: colors.primary,
              textDecoration: 'none',
              '&:hover': { textDecoration: 'underline' },
            }}
          >
            ONEBIT
          </Link>{' '}
          leverages Plaid to provide insights into your business's expenses and
          transactions.
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: colors.lightGray4, marginBottom: '30px' }}
        >
          By linking your accounts, you can gain a better understanding of your
          financial health and make more informed business decisions.
        </Typography>
        <Box sx={{ marginTop: '30px' }}>
          <PlaidLink
            autoFocus
            loadingData={loadingData}
            setLoadingData={setLoadingData}
            linkToken={linkToken}
            onSuccess={onSuccess}
            onExit={onExit}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default LinkAccountsPage;
